import React, { useState } from 'react';
import Modal from '@/components/General/Modal';
import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { ProjectService } from '@/services/ProjectService';

export const CreateProjectModal = ({ isModalOpen, setIsModalOpen, users, reloadProjects }) => {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [isSaving, setIsSaving] = useState(false);
  const [project, setProject] = useState({
    title: '',
    users: {},
  });

  const setTitle = (evt) => {
    setProject({ ...project, title: evt.target.value });
  };

  const toggleUser = (id: string) => {
    let isActive = project.users[id] ?? false;
    let tmpUsers = project.users;
    if (isActive) {
      delete tmpUsers[id];
    } else {
      tmpUsers[id] = parseInt(id);
    }

    setProject({ ...project, users: { ...tmpUsers } });
  };

  const safeProject = () => {
    setIsSaving(true);
    ProjectService.createProject(selectedAffiliate.id, project.title, Object.values(project.users))
      .then(() => {
        reloadProjects();
        createNotification(
          'Projekt ' + project.title + ' erfolgreich hinzugefügt.',
          'Du kannst nun in der Zeiterfassung auf dieses Projekt Zeiten buchen.',
          'success',
        );
        setProject({ title: '', users: {} });
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
        setIsModalOpen(false);
      });
  };

  return (
    <Modal
      modalName="create_project"
      headline={t('projects.create_project')}
      isOpen={isModalOpen}
      isLoading={isSaving}
      disableSafe={project.title === '' || Object.values(project.users).length === 0}
      onSafe={safeProject}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="space-y-1">
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">{t('projects.project_title')}</span>
          </label>
          <input
            type="text"
            name={'title'}
            onChange={setTitle}
            value={project.title}
            placeholder=""
            className="input input-bordered w-full"
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">{t('projects.member')}</span>
          </label>
          <div>
            {users.map((user: any, id) => {
              return (
                <div key={id} className="form-control">
                  <label className="label cursor-pointer justify-start">
                    <input
                      type="checkbox"
                      className="checkbox mr-4"
                      checked={project.users[user.id] !== undefined}
                      onChange={() => toggleUser(user.id)}
                    />
                    <span className="label-text">{user.fullname}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
