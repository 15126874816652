import { apiRequest } from '@/helper/ApiHelper';

interface OpeningHour {
  start: string;
  end: string;
  weekday: string;
}

interface Settings {
  title: string;
  state: string;
  opening_hours: OpeningHour[];
  time_management_config: {
    round_minute_step_start: string;
    round_minute_step_end: string;
    round_mode_end: string;
    round_mode_start: string;
  };
}

export const AffiliateSettingsService = {
  getSettings: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/settings',
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  updateSettings: async (affiliate_id: string, settings: Settings) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/settings',
        method: 'PATCH',
        data: settings,
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
