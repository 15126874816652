import React, { useEffect, useState } from 'react';
import { createErrorNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { AffiliateService } from '@/services/AffiliateService';
import { BsClock, BsFillSunFill, BsBandaid } from 'react-icons/bs';
import { AbsenceRequestDetailsModal } from '@/components/Modal/AbsenceRequestDetailsModal';
import { DateTime, DurationObjectUnits } from 'luxon';
import { StampService } from '@/services/StampService';
import { BlogPostsCarousel } from '@/components/Dashboard/blogposts/BlogPostsCarousel';
import { useTranslation } from 'react-i18next';
import { FinishStampModal } from '@/components/Modal/FinishStampModal';

export default function DashboardPage() {
  const { t } = useTranslation();
  const [absenceRequest, setAbsenceRequests] = useState([]);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isFinishStampModalOpen, setIsFinishStampModalOpen] = useState(false);
  const [selectedAbsence, setSelectedAbsence] = useState(null);
  const [workInfo, setWorkInfo] = useState({});
  const [absenceInfo, setAbsenceInfo] = useState({});
  const [currentTime, setCurrentTime] = useState(DateTime.local());
  const [punchClockLoading, setPunchClockLoading] = useState(false);
  const [punchClockData, setPunchClockData] = useState({
    start: null,
    duration: 0,
  });

  const getDashboard = () => {
    AffiliateService.getDashboard(selectedAffiliate.id)
      .then((res: any) => {
        setWorkInfo(res.work_info);
        setAbsenceInfo(res.absence_info);

        setAbsenceRequests(Object.values(res.open_requests));
      })
      .catch((err: any) => {
        createErrorNotification(err);
      });
  };

  useEffect(() => {
    getDashboard();
    getStampInfo();

    const intervalId = setInterval(() => {
      setCurrentTime(DateTime.local());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const openDetails = (requestId) => {
    setSelectedAbsence(requestId);
    setIsDetailsModalOpen(true);
  };

  const getStampInfo = () => {
    setPunchClockLoading(true);

    StampService.getLast(selectedAffiliate.id)
      .then((res: any) => {
        if (res.stamp !== null) {
          setPunchClockData({
            ...punchClockData,
            start: DateTime.fromISO(res.stamp),
          });
        }
      })
      .catch(createErrorNotification)
      .finally(() => setPunchClockLoading(false));
  };

  const openFinishModal = () => {
    setIsFinishStampModalOpen(true);
  };

  const startClock = () => {
    if (punchClockLoading) {
      return;
    }
    setPunchClockLoading(true);

    StampService.stamp(selectedAffiliate.id)
      .then((res: any) => {
        if (res.stamp !== null) {
          setPunchClockData({
            ...punchClockData,
            start: DateTime.fromISO(res.stamp),
          });
        }
      })
      .catch((err) => {
        createErrorNotification(err);
        if (err?.response?.data?.message === 'OVERLAPPING_SHIFT') {
          setPunchClockData({
            ...punchClockData,
            start: null,
          });
        }
      })
      .finally(() => setPunchClockLoading(false));
  };

  const clearClock = () => {
    setPunchClockData({
      duration: 0,
      start: null,
    });
  };

  let clockDiff = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  } as DurationObjectUnits;
  let clockIsTicking = punchClockData.start !== null;
  if (clockIsTicking) {
    const diff = currentTime.diff(punchClockData.start, ['hours', 'minutes', 'seconds']);
    clockDiff = diff.toObject();
  }

  // Helper function to format the time
  function formatTime(timeObject) {
    const hours = String(timeObject.hours).padStart(2, '0');
    const minutes = String(timeObject.minutes).padStart(2, '0');
    const seconds = String(Math.round(timeObject.seconds)).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <div>
      <div className={'grid grid-cols-2 lg:grid-cols-4 gap-5 p-6'}>
        <div className="p-6 rounded-xl relative bg-gradient-to-r from-primary to-secondary">
          <div>
            <div className="text-gray-100">{t('dashboard.squares.time_tracking')}</div>
            <span className="text-4xl font-bold text-white">
              {workInfo.saldo} {workInfo.unit}
            </span>
          </div>
          <div className="absolute left-2/4 top-3 xl:left-3/4 inset-0">
            <BsClock className={'opacity-20'} color="white" size={100} />
          </div>
        </div>
        <div className="p-6 rounded-xl relative bg-gradient-to-r from-primary to-secondary">
          <div>
            <div className="text-gray-100">{t('dashboard.squares.vacation_days')}</div>
            <span className="text-4xl font-bold text-white">{absenceInfo.netto}</span>
          </div>
          <div className="absolute left-2/4 top-3 xl:left-3/4 inset-0">
            <BsFillSunFill className={'opacity-20'} color="white" size={100} />
          </div>
        </div>
        <div className="p-6 rounded-xl relative bg-gradient-to-r from-primary to-secondary">
          <div>
            <div className="text-gray-100">{t('dashboard.squares.absence')}</div>
            <span className="text-4xl font-bold text-white">
              {t('time_tracking.number_days', {
                amount: absenceInfo.sick_days,
              })}
            </span>
          </div>
          <div className="absolute left-2/4 top-3 xl:left-3/4 inset-0">
            <BsBandaid className={'opacity-20'} color="white" size={100} />
          </div>
        </div>
        <div className="p-6 rounded-xl relative bg-gradient-to-r from-primary to-secondary">
          <div>
            <div className="text-gray-100">{currentTime.toFormat('dd.MM.yyyy')}</div>
            <span className="text-4xl font-bold text-white">{currentTime.toFormat('HH:mm')}</span>
          </div>
        </div>
      </div>

      <div className={'flex flex-row h-full w-full p-6'}>
        <div
          className={'w-full space-y-6 md:space-y-0 md:grid md:grid-cols-3 gap-6 md:items-start'}
        >
          <div className={'card border'}>
            <div className={'card-body p-6 space-y-2'}>
              <div className="flex items-center">
                <h2 className={'card-title'}>{t('time_tracking.punch_clock')}</h2>
                {clockIsTicking && (
                  <span className="relative flex h-3 w-3 ml-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                  </span>
                )}
              </div>
              <div className="text-center">
                {clockIsTicking && (
                  <span className="font-bold text-2xl md:text-3xl">{formatTime(clockDiff)}</span>
                )}
                {!clockIsTicking && (
                  <span className="font-bold text-2xl md:text-3xl">00:00:00</span>
                )}
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div
                  className={
                    'btn btn-base-100 ' +
                    (clockIsTicking || punchClockLoading ? 'btn-disabled' : '')
                  }
                  onClick={() => startClock()}
                >
                  {punchClockLoading && (
                    <span className="loading loading-spinner loading-xs"></span>
                  )}{' '}
                  {t('time_tracking.start')}
                </div>
                <div
                  className={
                    'btn btn-error ' + (!clockIsTicking || punchClockLoading ? 'btn-disabled' : '')
                  }
                  onClick={() => openFinishModal()}
                >
                  {punchClockLoading && (
                    <span className="loading loading-spinner loading-xs"></span>
                  )}{' '}
                  {t('time_tracking.stop')}
                </div>
              </div>
            </div>
          </div>

          {Object.values(absenceRequest).length > 0 && (
            <div className={'card border md:col-span-2'}>
              <div className={'card-body p-6'}>
                <h2 className={'card-title'}>{t('time_tracking.absence_requests')}</h2>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="hidden md:table-caption">Abwesenheit</th>
                      <th>Datum</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(absenceRequest).map((request: any, key: number) => {
                      return (
                        <tr key={key}>
                          <th>{request.user.name}</th>
                          <td className="hidden md:table-cell">
                            {t('time_tracking.absence.' + request.type)}
                          </td>
                          <td>
                            <span>{DateTime.fromISO(request.start).toFormat('dd.MM.')}</span>
                            {request.end !== null && (
                              <span> - {DateTime.fromISO(request.end).toFormat('dd.MM.')}</span>
                            )}
                          </td>
                          <td>
                            <div className="btn btn-ghost" onClick={() => openDetails(request.id)}>
                              {t('projects.details')}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <BlogPostsCarousel />
      <AbsenceRequestDetailsModal
        isModalOpen={isDetailsModalOpen}
        setIsModalOpen={setIsDetailsModalOpen}
        absenceId={selectedAbsence}
        reload={getDashboard}
      />
      <FinishStampModal
        isModalOpen={isFinishStampModalOpen}
        setIsModalOpen={setIsFinishStampModalOpen}
        clearClock={clearClock}
      />
    </div>
  );
}
