import React, { useEffect, useState } from 'react';
import { DateTime, Interval } from 'luxon';
import {
  BsAirplane,
  BsChevronLeft,
  BsChevronRight,
  BsClock,
  BsPlusLg,
  BsRocketTakeoff,
  BsUmbrella,
} from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { createErrorNotification } from '@/helper/ApiHelper';
import { CreateShiftModal } from '@/components/Modal/CreateShiftModal';
import { LoadingComponent } from '@/components/General/LoadingComponent';
import { SlotListModal } from '@/components/Modal/SlotListModal';
import { SlotAccordion } from '@/components/General/SlotAccordion';
import { ShiftService } from '@/services/ShiftService';
import { useTranslation } from 'react-i18next';

export default function CalendarPage() {
  const { t } = useTranslation();
  const [monthOffset, setMonthOffset] = useState(0);
  const [mark, setMark] = useState(DateTime.now());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState([]) as any;
  const [info, setInfo] = useState({}) as any;
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [selectedUser, setSelectedUser] = useState(null);
  const [holidays, setHolidays] = useState({});

  const today = DateTime.now().plus({ month: monthOffset });
  const start = today.startOf('month').startOf('week');
  const end = today.endOf('month').plus({ week: 1 }).startOf('week');
  const intervals = Interval.fromDateTimes(start, end)
    .splitBy({ day: 1 })
    .map((d) => d.start);

  const updateRange = (newOffset) => {
    setMonthOffset(newOffset);

    const start = DateTime.now().plus({ month: newOffset }).startOf('month');
    const end = DateTime.now().plus({ month: newOffset }).endOf('month').set({ millisecond: 0 });

    queryTimeOverview(start, end);
  };

  const openCalendarDayDetails = (userId) => {
    setSelectedUser(userId);
    setIsDetailModalOpen(true);
  };

  const queryTimeOverview = (pStart, pEnd) => {
    setIsLoading(true);
    let aStart = start;
    let aEnd = end;
    if (pStart !== null) {
      aStart = pStart;
    }

    if (pEnd !== null) {
      aEnd = pEnd;
    }

    ShiftService.getCalendar(
      selectedAffiliate.id,
      aStart.toISO({ suppressMilliseconds: true }) as string,
      aEnd.toISO({ suppressMilliseconds: true }) as string,
    )
      .then((res: any) => {
        setDays(res.calendar);
        setInfo(res.info);
        setHolidays(res.holidays);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateRange(0);
  }, []);

  return (
    <div>
      <div className={'grid grid-cols-3 py-6 bg-gray-50 border-b border-b-base-300'}>
        <div className={'flex flex-col lg:px-8 text-center lg:text-left'}>
          <div className={'mx-auto lg:hidden'}>
            <BsClock size={25} />
          </div>
          <span className={'text-md hidden lg:block text-gray-400'}>
            {t('time_tracking.working_time_month')}
          </span>
          <span className={'lg:text-3xl text-2xl font-bold pt-3 lg:pt-2'}>{info.netto} h</span>
          <span className={'text-sm text-gray-400 mt-0.5'}>
            {t('time_tracking.hours_month', {
              amount: info.brutto,
            })}
          </span>
        </div>

        <div className={'flex flex-col lg:px-8 text-center lg:text-left'}>
          <div className={'mx-auto lg:hidden'}>
            <BsRocketTakeoff size={25} />
          </div>
          <span className={'text-md hidden lg:block text-gray-400'}>
            {t('time_tracking.saldo')}
          </span>
          <span className={'lg:text-3xl text-2xl font-bold pt-3 lg:pt-2'}>
            <span className={parseFloat(info.amount) >= 0 ? 'text-primary' : 'text-error'}>
              {info.amount}
            </span>{' '}
            {info.unit}
          </span>
          <span className={'text-sm lg:hidden text-gray-400 mt-0.5'}>
            {t('time_tracking.saldo')}
          </span>
        </div>

        <div className={'flex flex-col lg:px-8 text-center lg:text-left'}>
          <div className={'mx-auto lg:hidden'}>
            <BsAirplane size={25} />
          </div>
          <span className={'text-md hidden lg:block text-gray-400'}>
            {t('general.table_headers.holiday')}
          </span>
          <span className={'lg:text-3xl text-2xl font-bold pt-3 lg:pt-2'}>
            {t('time_tracking.number_days', {
              amount: info.vacation_days_taken,
            })}
          </span>
          <span className={'text-sm text-gray-400 mt-0.5'}>{t('time_tracking.this_month')}</span>
        </div>
      </div>
      <div>
        <div
          className={
            'flex flex-row items-center justify-between rounded-tl-md rounded-tr-md p-3 bg-gray-50'
          }
        >
          <div className={'flex flex-row items-center'}>
            <span className={'p-4 cursor-pointer'} onClick={() => updateRange(monthOffset - 1)}>
              <BsChevronLeft size={17} className={''} />
            </span>
            <span className={'p-4 cursor-pointer'} onClick={() => updateRange(monthOffset + 1)}>
              <BsChevronRight size={17} className={''} />
            </span>
            <span className={'ml-0 text-lg select-none'}>
              {today.toFormat('MMMM')} {today.toFormat('y')}
            </span>
          </div>

          <button
            className={'btn btn-primary flex justify-center items-center text-white rounded-md'}
            onClick={() => setIsModalOpen(true)}
          >
            <BsPlusLg size={17} className={'lg:mr-2'} />
            <span className={'hidden lg:block'}>{t('general.add_time')}</span>
          </button>
        </div>
        <div className="grid grid-cols-7 border-2 border-gray-50">
          {intervals.map((day, index) => {
            // let day = DateTime.fromFormat(dayString, "y-MM-dd");
            let dayString = day?.toFormat('y-MM-dd');
            let isWeekend = day.weekday === 6 || day.weekday === 7;
            let isHoliday = Object.keys(holidays).includes(dayString);

            const totalLength = days[dayString]?.length ?? 0;
            let validatedAbsences = 0;
            let notValidatedAbsences = 0;
            let acceptedAbsences = 0;
            const numberAbsences = days[dayString]?.filter((day) => {
              if (day.is_validated) {
                validatedAbsences += 1;
              } else if (day.is_absence) {
                notValidatedAbsences += 1;
              }

              if (day.is_accepted) {
                acceptedAbsences += 1;
              }

              if (day.is_absence) {
                return day;
              }
            }).length;
            const numberShifts = totalLength - numberAbsences;

            return (
              <div
                key={index}
                onClick={() => setMark(day)}
                className={[
                  isHoliday ? 'bg-success bg-opacity-20 rounded' : '',
                  day.toFormat('MM') === today.toFormat('MM')
                    ? 'active:bg-base-200 duration-200'
                    : 'bg-base-200 opacity-50',
                  'text-center flex flex-col items-center p-2 border-2',
                  day.toFormat('dd.LL.y') === mark.toFormat('dd.LL.y')
                    ? 'border-primary rounded'
                    : 'border-gray-50',
                ].join(' ')}
              >
                <div className={'w-full text-left mb-2'}>
                  <span
                    className={[
                      isHoliday ? 'text-gray-300' : '',
                      isWeekend ? 'text-gray-300 text-lg' : 'text-gray-500 text-lg',
                    ].join(' ')}
                  >
                    {day.toFormat('d')}.
                  </span>
                </div>
                {isLoading ? (
                  <div className={'w-6 h-8'}>
                    <LoadingComponent noText={true} size={'xs'} />
                  </div>
                ) : days[dayString] !== undefined ? (
                  <div className={'w-full flex flex-wrap justify-center'}>
                    {numberShifts > 0 && (
                      <div
                        className={
                          'bg-gray-400 m-1 text-white rounded-full space-x-1 px-1.5 py-0.5 lg:px-2 lg:py-1 inline-flex items-center justify-center'
                        }
                      >
                        <BsClock size={17} />
                        <span className={'text-sm font-bold flex flex-row'}>{numberShifts}</span>
                      </div>
                    )}
                    {acceptedAbsences > 0 && (
                      <div
                        className={
                          'bg-primary m-1 text-white rounded-full space-x-1 px-1.5 py-0.5 lg:px-2 lg:py-1 inline-flex items-center justify-center'
                        }
                      >
                        <BsUmbrella size={17} />
                        <span className={'text-sm font-bold flex flex-row'}>
                          {acceptedAbsences}
                        </span>
                      </div>
                    )}
                    {notValidatedAbsences > 0 && (
                      <div
                        className={
                          'bg-warning m-1 text-white rounded-full space-x-1 px-1.5 py-0.5 lg:px-2 lg:py-1 inline-flex items-center justify-center'
                        }
                      >
                        <BsUmbrella size={17} />
                        <span className={'text-sm font-bold flex flex-row'}>
                          {notValidatedAbsences}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={'w-6 h-6'}></div>
                )}
              </div>
            );
          })}
        </div>
        <div className="prose my-6 px-6">
          <h2>{mark.toFormat('d. MMMM y')}</h2>
        </div>
        <SlotAccordion
          groupSlots={false}
          slots={days[mark.toFormat('y-MM-dd')]}
          openDetails={openCalendarDayDetails}
        />
      </div>
      <CreateShiftModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reload={() => updateRange(monthOffset)}
        defaultDay={mark.toFormat('y-LL-dd')}
      />

      <SlotListModal
        isModalOpen={isDetailModalOpen}
        setIsModalOpen={setIsDetailModalOpen}
        reload={() => updateRange(monthOffset)}
        userId={selectedUser}
        start={mark.startOf('day')}
        end={mark.endOf('day').set({ millisecond: 0 })}
      />
    </div>
  );
}
