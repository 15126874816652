import React, { useEffect, useState } from 'react';
import { DateTime, Interval } from 'luxon';
import {
  BsAirplane,
  BsChevronLeft,
  BsChevronRight,
  BsClock,
  BsHospital,
  BsPlusLg,
  BsRocketTakeoff,
} from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { createErrorNotification } from '@/helper/ApiHelper';
import { AbsenceRequestDetailsModal } from '@/components/Modal/AbsenceRequestDetailsModal';
import { CreateAbsenceModal } from '@/components/Modal/CreateAbsenceModal';
import { AbsenceService } from '@/services/AbsenceService';
import { useTranslation } from 'react-i18next';

export default function AbsenceOverview() {
  const { t } = useTranslation();
  const [monthOffset, setMonthOffset] = useState(0);
  const [mark, setMark] = useState(DateTime.now());
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState([]) as any;
  const [info, setInfo] = useState({}) as any;
  const [yearInfo, setYearInfo] = useState({}) as any;
  const affiliate = useSelector((state: any) => state.affiliate);
  const [selectedAbsence, setSelectedAbsence] = useState(null);
  const [absenceBar, setAbsenceBar] = useState({
    taken: 0,
    open: 0,
    available: 100,
  });
  const [holidays, setHolidays] = useState({});

  const today = DateTime.now().plus({ month: monthOffset });
  const start = today.startOf('month').startOf('week');
  const end = today.endOf('month').plus({ week: 1 }).startOf('week');
  const intervals = Interval.fromDateTimes(start, end)
    .splitBy({ day: 1 })
    .map((d) => d.start);

  const intervalWeeks = [];
  intervals.forEach((day: DateTime | null) => {
    let weekOfYear = day?.toFormat('WW');
    let month = day?.toFormat('L');
    let year = day?.toFormat('yyyy');

    if (parseInt(weekOfYear) === 1 && parseInt(month) === 12) {
      year = (parseInt(year) + 1).toString();
    }

    let index = parseInt(year + weekOfYear);
    if (intervalWeeks[index] === undefined) {
      intervalWeeks[index] = [];
    }

    intervalWeeks[index].push(day);
  });

  const updateRange = (newOffset = null) => {
    let offset = monthOffset;
    if (newOffset !== null) {
      offset = newOffset;
      setMonthOffset(newOffset);
    }

    const start = DateTime.now().plus({ month: offset }).startOf('month');
    const end = DateTime.now().plus({ month: offset }).endOf('month').set({ millisecond: 0 });

    queryTimeOverview(start, end);
  };

  const openDetails = (absenceId) => {
    setSelectedAbsence(absenceId);
    setIsDetailModalOpen(true);
  };

  const queryTimeOverview = (start, end) => {
    setIsLoading(true);
    AbsenceService.getAbsenceCalendar(
      affiliate.selectedAffiliate.id,
      start.toISO({ suppressMilliseconds: true }),
      end.toISO({ suppressMilliseconds: true }),
    )
      .then((result: any) => {
        setDays(result.calendar);
        setYearInfo(result.absence_year);
        setInfo(result.info);

        let brutto = result.absence_year.brutto;
        let validated = brutto - result.absence_year.netto;
        let open = result.absence_year.open_vacation_days;
        let available = brutto - validated - open;

        setAbsenceBar({
          available: Math.round((available * 100) / brutto),
          open: Math.round((open * 100) / brutto),
          taken: Math.round((validated * 100) / brutto),
        });
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateRange(0);
  }, []);

  return (
    <div>
      <div className={'border-b border-b-base-300'}>
        <div className={'grid grid-cols-3 py-6 bg-gray-50'}>
          <div className={'flex flex-col lg:px-8 text-center lg:text-left'}>
            <div className={'mx-auto lg:hidden'}>
              <BsClock size={25} />
            </div>
            <span className={'text-md hidden lg:block text-gray-400'}>
              {t('time_tracking.vacation_this_year')}
            </span>
            <span className={'lg:text-3xl text-2xl font-bold pt-3 lg:pt-2'}>
              {t('time_tracking.number_days', {
                amount: yearInfo.netto,
              })}
            </span>
            <span className={'text-sm text-gray-400 mt-0.5'}>
              {t('time_tracking.days_year', {
                min: '',
                max: yearInfo.brutto,
              })}
            </span>
          </div>

          <div className={'flex flex-col lg:px-8 text-center lg:text-left'}>
            <div className={'mx-auto lg:hidden'}>
              <BsRocketTakeoff size={25} />
            </div>
            <span className={'text-md hidden lg:block text-gray-400'}>
              {t('time_tracking.holidays')}
            </span>
            <span className={'lg:text-3xl text-2xl font-bold pt-3 lg:pt-2'}>
              <span>
                {t('time_tracking.number_days', {
                  amount: info.holidays,
                })}
              </span>
            </span>
            <span className={'text-sm lg:hidden text-gray-400 mt-0.5'}>
              {t('time_tracking.holidays')}
            </span>
          </div>

          <div className={'flex flex-col lg:px-8 text-center lg:text-left'}>
            <div className={'mx-auto lg:hidden'}>
              <BsHospital size={25} />
            </div>
            <span className={'text-md hidden lg:block text-gray-400'}>
              {t('time_tracking.absence.sick')}
            </span>
            <span className={'lg:text-3xl text-2xl font-bold pt-3 lg:pt-2'}>
              {t('time_tracking.number_days', {
                amount: info.sick_days,
              })}
            </span>
            <span className={'text-sm text-gray-400 mt-0.5'}>{t('time_tracking.this_month')}</span>
          </div>
        </div>

        <div className={'flex flex-row space-x-1 p-6 pt-0 bg-gray-50'}>
          <progress
            style={{ width: absenceBar.available + '%' }}
            className={'progress progress-success'}
            value={100}
            max="100"
          ></progress>
          <progress
            style={{ width: absenceBar.open + '%' }}
            className={'progress progress-warning'}
            value={100}
            max="100"
          ></progress>
          <progress
            style={{ width: absenceBar.taken + '%' }}
            className={'progress progress-primary'}
            value={0}
            max="100"
          ></progress>
        </div>
      </div>
      <div className={'border-b border-gray-50'}>
        <div
          className={
            'flex flex-row items-center justify-between rounded-tl-md rounded-tr-md p-3 bg-gray-50'
          }
        >
          <div className={'flex flex-row items-center'}>
            <span className={'p-4 cursor-pointer'} onClick={() => updateRange(monthOffset - 1)}>
              <BsChevronLeft size={17} className={''} />
            </span>
            <span className={'p-4 cursor-pointer'} onClick={() => updateRange(monthOffset + 1)}>
              <BsChevronRight size={17} className={''} />
            </span>
            <span className={'ml-0 text-lg select-none'}>
              {today.toFormat('MMMM')} {today.toFormat('y')}
            </span>
          </div>

          <button
            className={'btn btn-primary flex justify-center items-center text-white rounded-md'}
            onClick={() => setIsCreateModalOpen(true)}
          >
            <BsPlusLg size={17} className={'lg:mr-2'} />
            <span className={'hidden lg:block'}>{t('general.add_absence')}</span>
          </button>
        </div>
        {Object.values(intervalWeeks).map((week, index) => {
          let emptyWeek = true;
          Object.values(days).forEach((day) => {
            let start = DateTime.fromISO(day.start);
            let end = DateTime.fromISO(day.end);

            if (
              start.toFormat('W') === week[0].toFormat('W') ||
              end.toFormat('W') === week[0].toFormat('W')
            ) {
              emptyWeek = false;
              return;
            }
          });

          return (
            <div key={index}>
              <div className="grid grid-cols-7 border-2 border-gray-50">
                {week.map((day, index) => {
                  let dayString = day.toFormat('y-MM-dd');
                  let isWeekend = day.weekday === 6 || day.weekday === 7;
                  let isHoliday = Object.keys(holidays).includes(dayString);

                  return (
                    <div
                      key={'date' + index}
                      onClick={() => setMark(day)}
                      className={[
                        isHoliday ? 'bg-success bg-opacity-20 rounded' : '',
                        'text-center flex flex-col items-center p-2 border-2 border-white',
                        day.toFormat('MM') === today.toFormat('MM')
                          ? 'active:bg-base-200 duration-200'
                          : 'bg-base-200 opacity-50',
                        day.toFormat('dd.LL.y') === mark.toFormat('dd.LL.y')
                          ? 'border-primary rounded'
                          : 'border-gray-50',
                      ].join(' ')}
                    >
                      <div className={'w-full text-left'}>
                        <span
                          className={[
                            isHoliday ? 'text-gray-300' : '',
                            isWeekend ? 'text-gray-300 text-lg' : 'text-gray-500 text-lg',
                          ].join(' ')}
                        >
                          {day.toFormat('d')}.
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>

              {emptyWeek && (
                <div className={'grid grid-cols-1 border-2 border-white'}>
                  <div className={'text-center p-2'}>Keine Urlaube</div>
                </div>
              )}

              {!emptyWeek &&
                Object.values(days).map((absenceRequest, key) => {
                  let absences = Object.keys(absenceRequest.absences);
                  let dates = [];
                  let weekIds = {};
                  Object.keys(intervalWeeks).map((weekId) => {
                    weekIds[weekId] = false;
                  });

                  week.forEach((day) => {
                    if (absences.includes(day.toFormat('y-MM-dd'))) {
                      weekIds[day.toFormat('W')] = true;
                      dates.push(day.toFormat('y-MM-dd'));
                    }
                  });

                  if (dates.length === 0) {
                    return;
                  }

                  return (
                    <div
                      key={key + '-' + index}
                      className={'grid grid-cols-7 border-0 border-base-200'}
                    >
                      {week.map((day, index2) => {
                        if (absences.includes(day.toFormat('y-MM-dd'))) {
                          let content = '';
                          if (day.toFormat('y-MM-dd') === dates[0]) {
                            content = absenceRequest.user.name;
                          }

                          return (
                            <div
                              key={index2}
                              onClick={() => openDetails(absenceRequest.id)}
                              className={
                                'text-white p-1 px-3 text-sm hover:cursor-pointer ' +
                                (absenceRequest.status === 'accepted'
                                  ? 'bg-primary'
                                  : absenceRequest.status === 'declined'
                                    ? 'bg-error'
                                    : 'bg-base-200')
                              }
                            >
                              {content}
                            </div>
                          );
                        } else {
                          return <div key={index2} className={''}></div>;
                        }
                      })}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <AbsenceRequestDetailsModal
        isModalOpen={isDetailModalOpen}
        setIsModalOpen={setIsDetailModalOpen}
        absenceId={selectedAbsence}
        reload={updateRange}
      />
      <CreateAbsenceModal
        defaultDay={DateTime.now().plus({ month: monthOffset }).toFormat('y-MM-dd')}
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
        reload={updateRange}
      />
    </div>
  );
}
