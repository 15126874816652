import React, { useState } from 'react';
import Modal from '@/components/General/Modal';
import { apiRequest, createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { AffiliateService } from '@/services/AffiliateService';
import { ProjectService } from '@/services/ProjectService';

export const DeleteProjectModal = ({
  isModalOpen,
  setIsModalOpen,
  projectId,
  projectName,
  reload,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const deleteProject = () => {
    setIsSaving(true);
    ProjectService.deleteProject(projectId)
      .then(reload)
      .catch(createErrorNotification)
      .finally(() => {
        setIsSaving(false);
        setIsModalOpen(false);
      });
  };

  return (
    <Modal
      modalName="edit_employee"
      headline={t('projects.delete_project')}
      isOpen={isModalOpen}
      isLoading={isSaving}
      primaryButtonText={'delete'}
      primaryButtonClass={'btn-error'}
      onSafe={deleteProject}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="space-y-1">
        <p className="w-full">
          {t('employee_page.modals.delete_employee_message', {
            userName: projectName,
          })}
        </p>
      </div>
    </Modal>
  );
};
