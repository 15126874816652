import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from '@/global/authSlice';
import affiliateReducer from '@/global/affiliateSlice';
import userReducer from '@/global/userSlice';
import onboardingReducer from '@/global/onboardingSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  affiliate: affiliateReducer,
  user: userReducer,
  onboarding: onboardingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

const persistor = persistStore(store);
export { store, persistor };
