import React from 'react';
import { Settings } from 'luxon';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { router } from '@/global/router';

function App() {
  const { i18n } = useTranslation();
  Settings.defaultLocale = i18n.language;
  Settings.defaultZone = 'Europe/Berlin';

  return <RouterProvider router={router} />;
}

export default App;
