import { apiRequest } from '@/helper/ApiHelper';

export const TerminalService = {
  getTerminal: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/terminal/affiliate/' + affiliate_id,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  createTerminal: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/terminal/affiliate/' + affiliate_id,
        method: 'POST',
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
