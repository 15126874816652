import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from '@/components/Form/User/AddPaymentElementForm';
import { stripePromise } from '@/global/stripe';
import { PaymentService } from '@/services/PaymentService';
import { OnboardingLayout } from '@/components/Onboarding/OnboardingLayout';

export default function Step_Payment() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [hasDefault, setHasDefault] = useState(false);
  const [portalUrl, setPortalUrl] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    PaymentService.getSetupIntent()
      .then((res: any) => {
        setClientSecret(res.client_secret);
        setHasDefault(res.has_default);
        setPortalUrl(res.stripe_portal);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const previousStep = () => {
    navigate('/onboarding/select-license');
  };

  const nextStep = () => {
    navigate('/onboarding/confirm');
  };

  return (
    <OnboardingLayout>
      <div className={'px-6 max-w-4xl'}>
        <h2 className="text-2xl font-bold text-primary">{t('onboarding.header.payment_method')}</h2>
        <p className={'text-gray-700'}>{t('onboarding.payment_method.subtitle')}</p>

        {hasDefault ? (
          <div>
            {t('onboarding.payment_method.payment_method_exists')}
            <br />
            <a
              className="font-medium text-primary dark:text-blue-500 hover:underline"
              href={portalUrl}
              target="_blank"
            >
              {t('onboarding.payment_method.payment_method_exists_edit')}
            </a>
          </div>
        ) : (
          <div className={'mx-auto'}>
            {clientSecret !== '' && (
              <Elements
                stripe={stripePromise}
                options={{
                  loader: 'always',
                  paymentMethodCreation: 'manual',
                  clientSecret: clientSecret,
                }}
              >
                <CheckoutForm clientSecret={clientSecret} />
              </Elements>
            )}
          </div>
        )}

        <div className="join grid grid-cols-3 mt-16 w-full sm:w-96 mx-auto px-6">
          <button className="join-item btn btn-outline" onClick={() => previousStep()}>
            {t('general.back')}
          </button>
          <button
            className={'join-item btn btn-outline'}
            disabled={!clientSecret}
            onClick={() => nextStep()}
          >
            {t('general.skip')}
          </button>
        </div>
      </div>
    </OnboardingLayout>
  );
}
