import React from 'react';

export interface NavbarProps {
  start?: React.ReactNode;
  main?: React.ReactNode;
  end?: React.ReactNode;
}

export default function Navbar({ start, main, end }: NavbarProps) {
  return (
    <div className="top-0 navbar p-0 bg-base-100 border-b border-base-300 rounded-none sticky z-10 flex flex-col lg:flex-row">
      <div className="lg:navbar-start flex items-center">{start}</div>
      <div className="lg:navbar-center flex items-center">{main}</div>
      <div className="lg:navbar-end w-full flex items-center">{end}</div>
    </div>
  );
}
