import React, { useEffect, useState } from 'react';
import { createErrorNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { CreateProjectModal } from '@/components/Modal/CreateProjectModal';
import { EditProjectModal } from '@/components/Modal/EditProjectModal';
import { SlotListModal } from '@/components/Modal/SlotListModal';
import UpgradeSubscriptionBanner from '@/components/Commerce/UpgradeSubscriptionBanner';
import { ProjectService } from '@/services/ProjectService';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '@/components/Menu/Navbar';
import { Project, ProjectOverviewTable } from '@/components/Tables/ProjectOverviewTable';
import { useTranslation } from 'react-i18next';

export default function ProjectOverview() {
  const { t } = useTranslation();
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const navigate = useNavigate();
  const location = useLocation();

  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isShiftListModalOpen, setIsShiftListModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({
    title: '',
    users: {},
  });
  const [hasLicense, setHasLicense] = useState(true);

  const openEditModal = (project) => {
    setSelectedProject(project);
    setIsEditModalOpen(true);
  };

  const gotoExportPage = (project) => {
    navigate('/time/export', {
      state: {
        user: {
          id: null,
          name: project.title,
        },
        start: null,
        end: null,
        projectId: project.id,
        from: location,
      },
    });
  };

  const getProjects = () => {
    setIsLoading(true);
    ProjectService.getProjects(selectedAffiliate.id)
      .then((res: any) => {
        setProjects(res.projects as Project[]);
        setUsers(res.users);
      })
      .catch((err) => {
        if (err.response?.data?.message !== 'PAYMENTPLAN_NOT_FOUND') {
          createErrorNotification(err);
        } else {
          setHasLicense(false);
          setProjects([
            {
              id: 1,
              title: 'Kunde A',
              hours: {
                total: 0,
                current_month: 0,
              },
              users: [
                {
                  id: 0,
                  name: 'Max Muster',
                },
                {
                  id: 1,
                  name: 'Maxime Muster',
                },
              ],
            },
            {
              id: 2,
              title: 'Projekt Workplan',
              hours: {
                total: 0,
                current_month: 0,
              },
              users: [
                {
                  id: 0,
                  name: 'Max Muster',
                },
                {
                  id: 1,
                  name: 'Maxime Muster',
                },
              ],
            },
          ]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className={'relative h-full'}>
      {!hasLicense && <UpgradeSubscriptionBanner addon={'project'} />}

      <div className={!hasLicense ? 'blur-sm' : ''}>
        <Navbar
          end={
            <div
              className="p-4 w-full flex flex-row justify-end"
              onClick={() => setIsCreateModalOpen(true)}
            >
              <div className={'btn btn-primary text-white'}>{t('projects.create_new_project')}</div>
            </div>
          }
        />

        <ProjectOverviewTable
          projects={projects}
          openEditModal={openEditModal}
          gotoExportPage={gotoExportPage}
        />
        <CreateProjectModal
          isModalOpen={isCreateModalOpen}
          setIsModalOpen={setIsCreateModalOpen}
          users={users}
          reloadProjects={getProjects}
        />
        <EditProjectModal
          isModalOpen={isEditModalOpen}
          setIsModalOpen={setIsEditModalOpen}
          users={users}
          project={selectedProject}
          reloadProjects={getProjects}
        />
      </div>
    </div>
  );
}
