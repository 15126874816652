import React, { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import EmptyModal from '@/components/General/EmptyModal';
import { t } from 'i18next';

export const DropdownActions = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const actionWrapper = (action) => {
    setIsOpen(false);
    action();
  };

  return (
    <div className={'flex justify-end lg:justify-start'}>
      <ul className="hidden lg:display  menu menu-vertical lg:menu-horizontal rounded-box">
        <ListItems actions={actions} actionWrapper={actionWrapper} />
      </ul>

      <EmptyModal modalName={'table-actions'} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ul className="menu w-full rounded-box">
          <ListItems actions={actions} actionWrapper={actionWrapper} />
          <li className={'btn btn-ghost bg-base-200 text-center'} onClick={() => setIsOpen(false)}>
            {t('general.cancel')}
          </li>
        </ul>
      </EmptyModal>

      <div onClick={() => setIsOpen(true)} className="lg:hidden">
        <label tabIndex={0} className="btn btn-ghost m-1">
          <BsThreeDots size={17} />
        </label>
      </div>
    </div>
  );
};

const ListItems = ({ actions, actionWrapper }) => {
  return (
    <>
      {actions.map((action, key) => {
        return (
          <li key={key} onClick={() => actionWrapper(action.action)} className={'mb-2'}>
            <a className={'btn btn-ghost py-3 flex justify-start'}>
              <div className={'px-2'}>{action.icon}</div>{' '}
              <span className={'lg:hidden'}>{action.title}</span>
            </a>
          </li>
        );
      })}
    </>
  );
};
