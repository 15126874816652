import { apiRequest } from '@/helper/ApiHelper';
import { UserConfigurationDynamic, UserConfigurationStatic } from '@/types/User/UserConfiguration';
import { DateTime } from 'luxon';

export const AffiliateService = {
  createAffiliate: async (title: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate',
        method: 'POST',
        data: {
          title: title,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  createInvite: async (email: string, full_name: string, affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/user/invite',
        method: 'POST',
        data: {
          user_email: email,
          user_name: full_name,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getInvites: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/user/invites',
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  deleteInvite: async (affiliate_id: string, invite_id: number) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/user/invite',
        method: 'DELETE',
        data: {
          invite_id: invite_id,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  join: async (token: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/user/affiliate/join/' + token,
        method: 'POST',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getUserProfile: async (affiliate_id: string, user_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/user/' + user_id,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getCurrentUserConfiguration: async (affiliate_id: string, user_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/user/' + user_id + '/configuration',
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  updateUserConfigurations: async (
    affiliate_id: string,
    user_id: string,
    configs: UserConfigurationDynamic[],
  ) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/user/' + user_id + '/configuration/dynamic',
        method: 'PUT',
        data: configs,
      })
        .then(resolve)
        .catch(reject);
    });
  },
  updateStaticUserConfiguration: async (
    affiliate_id: string,
    user_id: string,
    config: UserConfigurationStatic,
  ) => {
    return apiRequest({
      url: '/api/affiliate/' + affiliate_id + '/user/' + user_id + '/configuration/static',
      method: 'PUT',
      data: config,
    });
  },
  deleteUserFromAffiliate: async (affiliate_id: string, user_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/user/delete',
        method: 'POST',
        data: {
          user_id: user_id,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getDashboard: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/affiliate/' + affiliate_id + '/dashboard',
        method: 'POST',
        data: {
          start: DateTime.local().startOf('year').toISO({ suppressMilliseconds: true }),
          end: DateTime.local()
            .endOf('day')
            .set({ millisecond: 0 })
            .toISO({ suppressMilliseconds: true }),
          timezone: DateTime.local().toFormat('z'),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
