import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { apiRequest, createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { AbsenceService } from '@/services/AbsenceService';
import { ShiftService } from '@/services/ShiftService';

export const CreateAbsenceModal = ({ isModalOpen, setIsModalOpen, reload, defaultDay }) => {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const user = useSelector((state: any) => state.user);
  const [users, setUsers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [vacation, setVacation] = useState({
    user_id: user.id,
    vacation_start: defaultDay,
    vacation_end: '',
    vacation_type: 'vacation',
    time_start: '',
    time_end: '',
  });

  const setVacationData = (evt) => {
    setVacation({ ...vacation, [evt.target.name]: evt.target.value });
  };

  const createAbsence = () => {
    setIsSaving(true);
    let startDateString =
      DateTime.fromSQL(vacation.vacation_start).toISO({
        suppressMilliseconds: true,
      }) ?? '';
    let endDateString =
      DateTime.fromSQL(vacation.vacation_end).toISO({
        suppressMilliseconds: true,
      }) ?? '';
    if (vacation.vacation_end === '') {
      endDateString = startDateString;
    }

    let body = {
      affiliate_id: selectedAffiliate.id,
      user_id: parseInt(vacation.user_id),
      from: startDateString,
      until: endDateString,
      timezone: DateTime.now().toFormat('z'),
      type: vacation.vacation_type,
    };

    if (vacation.time_start !== '' && vacation.time_end !== '') {
      body = {
        ...body,
        start: vacation.time_start + ':00',
        end: vacation.time_end + ':00',
      };
    } else {
      body = {
        ...body,
        is_fullday: true,
      };
    }

    AbsenceService.createAbsence(body)
      .then(async () => {
        createNotification(
          'Abwesenheit beantragt',
          'Abwesenheit für den ' +
            DateTime.fromSQL(vacation.vacation_start).toFormat('dd.LL.y') +
            ' wurde erfolgreich beantragt.',
          'success',
        );
        await reload(null, null);
        setIsModalOpen(false);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    if (users.length === 0) {
      ShiftService.getModal(selectedAffiliate.id)
        .then((res: any) => {
          setUsers(res);
        })
        .catch((err) => {
          createErrorNotification(err);
        });
    }

    setVacation({ ...vacation, vacation_start: defaultDay });
  }, [defaultDay]);

  return (
    <Modal
      modalName="add_time"
      headline={t('time_tracking.absence.title')}
      isOpen={isModalOpen}
      isLoading={isSaving}
      onSafe={createAbsence}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">Benutzer</span>
        </label>
        <select
          name={'user_id'}
          value={vacation.user_id}
          onChange={setVacationData}
          className="select select-bordered w-full max-w"
        >
          <option value={''}>{t('general.please_select')}</option>
          {users.map((user: any, key: number) => {
            return (
              <option key={key} value={user.id}>
                {user.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Art</span>
        </label>
        <select
          name={'vacation_type'}
          value={vacation.vacation_type}
          onChange={setVacationData}
          className="select select-bordered w-full max-w"
        >
          <option value={'vacation'}>Urlaub</option>
          <option value={'sick'}>Krankheit</option>
        </select>
      </div>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Start</span>
        </label>
        <input
          type="date"
          name={'vacation_start'}
          value={vacation.vacation_start}
          onChange={setVacationData}
          className="input input-bordered w-full"
        />
      </div>
      <div className="flex flex-col w-full border-opacity-50">
        <div className={'grid grid-cols-2 space-x-2'}>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Von</span>
            </label>
            <input
              type="time"
              name={'time_start'}
              value={vacation.time_start}
              onChange={setVacationData}
              placeholder=""
              className="input input-bordered w-full"
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Bis</span>
            </label>
            <input
              type="time"
              name={'time_end'}
              value={vacation.time_end}
              onChange={setVacationData}
              className="input input-bordered w-full"
            />
          </div>
        </div>
        <div className="divider mb-0">oder</div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Ende</span>
          </label>
          <input
            type="date"
            name={'vacation_end'}
            value={vacation.vacation_end}
            onChange={setVacationData}
            className="input input-bordered w-full"
          />
        </div>
      </div>
    </Modal>
  );
};
