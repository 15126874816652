import React, { useEffect, useState } from 'react';
import { PencilIcon, TrashIcon } from 'lucide-react';
import { UserConfigurationDynamic } from '@/types/User/UserConfiguration';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { EditEmployeeSettingsModal } from '../Modal/EditEmployeeSettingsModal';
import { BsExclamationCircle } from 'react-icons/bs';
import { useBlocker } from 'react-router-dom';
import Modal from '../General/Modal';

interface UserConfigurationDynamicTableProps {
  configs: UserConfigurationDynamic[];
  cancelConfig: () => void;
  saveConfig: (any) => Promise<void>;
  isPending: boolean;
}

export const UserConfigurationDynamicTable: React.FC<UserConfigurationDynamicTableProps> = ({
  configs,
  isPending,
  cancelConfig,
  saveConfig,
}) => {
  const [userConfigs, setUserConfigs] = useState<UserConfigurationDynamic[]>([]);
  const { t } = useTranslation();
  const [hasChange, setHasChange] = useState<boolean>();
  const [counter, setCounter] = useState(-1);
  const [selectedConfig, setSelectedConfig] = useState<UserConfigurationDynamic | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let blocker = useBlocker(() => hasChange === true);

  useEffect(() => {
    setUserConfigs(
      configs?.map(
        (config) =>
          ({
            ...config,
            valid_from: config.valid_from
              ? DateTime.fromISO(config.valid_from).toFormat('yyyy-LL-dd')
              : '',
            valid_until: config.valid_until
              ? DateTime.fromISO(config.valid_until).toFormat('yyyy-LL-dd')
              : '',
          }) as UserConfigurationDynamic,
      ),
    );
  }, [configs]);

  const openModal = (config) => {
    setIsModalOpen(true);
    setSelectedConfig(config);
  };

  const resetConfig = async () => {
    setUserConfigs(
      configs.map(
        (config) =>
          ({
            ...config,
            valid_from: config.valid_from
              ? DateTime.fromISO(config.valid_from).toFormat('yyyy-LL-dd')
              : '',
            valid_until: config.valid_until
              ? DateTime.fromISO(config.valid_until).toFormat('yyyy-LL-dd')
              : '',
          }) as UserConfigurationDynamic,
      ),
    );
    await cancelConfig();
    setHasChange(false);
  };

  const updateConfig = (updatedConfig: UserConfigurationDynamic) => {
    setUserConfigs((prevConfigs) => {
      return prevConfigs.map((config) => {
        if (config.id === updatedConfig.id) {
          return updatedConfig;
        }
        return config;
      });
    });
    setHasChange(true);
    setIsModalOpen(false);
  };

  const addRow = () => {
    const newConfigs = [...userConfigs];
    newConfigs.push({
      id: counter,
      valid_from: '',
      valid_until: '',
      weekly_hours_configuration: {
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
      },
      vacation_days: 0,
    });
    setCounter(counter - 1);
    setUserConfigs(newConfigs);
  };

  const deleteRow = (index: number) => {
    const newConfigs = [...userConfigs];
    newConfigs.splice(index, 1);
    setUserConfigs(newConfigs);
  };

  const handleSaveConfig = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    await saveConfig(userConfigs);
    setHasChange(false);
    form.reset();
  };

  const Header = () => (
    <tr>
      <th>{t('general.edit')}</th>
      <th>{t('general.delete')}</th>
      <th>{t('time_tracking.start')}</th>
      <th>{t('time_tracking.end')}</th>
      <th>{t('general.day.monday')}</th>
      <th>{t('general.day.tuesday')}</th>
      <th>{t('general.day.wednesday')}</th>
      <th>{t('general.day.thursday')}</th>
      <th>{t('general.day.friday')}</th>
      <th>{t('general.day.saturday')}</th>
      <th>{t('general.day.sunday')}</th>
      <th>{t('profile_page.employee_vacation_days')}</th>
    </tr>
  );

  return (
    <>
      {blocker.state === 'blocked' && (
        <Modal
          isOpen={true}
          onSafe={() => blocker.proceed()}
          onClose={() => blocker.reset()}
          headline={t('general.unsaved_changes')}
          primaryButtonText="yes"
          modalName="dynamic_config_unsaved_changes"
        >
          <p>{t('profile_page.leave_without_saving')}</p>
        </Modal>
      )}
      <form className="" onSubmit={handleSaveConfig}>
        {hasChange && (
          <div role="alert" className="alert alert-warning m-6 w-fit">
            <BsExclamationCircle size={20} className="text-base-500" />
            <span>{t('general.unsaved_changes')}</span>
          </div>
        )}

        <div className="max-w-[calc(100vw-4rem)] lg:max-w-[calc(100vw-24rem)] overflow-x-scroll m-6">
          <table className="table table-auto table-zebra w-full overflow-x-scroll">
            <thead>
              <Header />
            </thead>
            <tbody>
              {userConfigs?.map((row: UserConfigurationDynamic, index: number) => (
                <tr key={index}>
                  <td>
                    <div onClick={() => openModal(row)} className="p-2 btn btn-ghost">
                      <PencilIcon size={18} />
                    </div>
                  </td>
                  <td>
                    <div onClick={() => deleteRow(index)} className="p-2 btn btn-ghost">
                      <TrashIcon size={18} />
                    </div>
                  </td>
                  <td>
                    <span>
                      {row.valid_from === ''
                        ? '-'
                        : DateTime.fromISO(row.valid_from).toFormat('dd.MM.yyyy')}
                    </span>
                  </td>
                  <td>
                    <span>
                      {row.valid_until === ''
                        ? '-'
                        : DateTime.fromISO(row.valid_until).toFormat('dd.MM.yyyy')}
                    </span>
                  </td>
                  <td>
                    <span>{row.weekly_hours_configuration.monday ?? 0}</span>
                  </td>
                  <td>
                    <span>{row.weekly_hours_configuration.tuesday ?? 0}</span>
                  </td>
                  <td>
                    <span>{row.weekly_hours_configuration.wednesday ?? 0}</span>
                  </td>
                  <td>
                    <span>{row.weekly_hours_configuration.thursday ?? 0}</span>
                  </td>
                  <td>
                    <span>{row.weekly_hours_configuration.friday ?? 0}</span>
                  </td>
                  <td>
                    <span>{row.weekly_hours_configuration.saturday ?? 0}</span>
                  </td>
                  <td>
                    <span>{row.weekly_hours_configuration.sunday ?? 0}</span>
                  </td>
                  <td>
                    <span>{row.vacation_days ?? 0}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-row justify-start px-6 gap-x-3 flex-wrap gap-y-3">
          <button
            type="submit"
            className={'btn btn-primary ' + (isPending ? 'btn-disabled' : '')}
            disabled={isPending}
          >
            {t('general.save')}
          </button>
          <button
            type="button"
            className={'btn btn-cancel'}
            disabled={isPending}
            onClick={() => resetConfig()}
          >
            {t('general.cancel')}
          </button>
          <button type="button" className={'btn btn-cancel'} disabled={isPending} onClick={addRow}>
            {t('profile_page.add_user_config')}
          </button>
        </div>

        <EditEmployeeSettingsModal
          initialConfig={selectedConfig}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          saveConfig={updateConfig}
        />
      </form>
    </>
  );
};
