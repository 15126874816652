import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const RequireAuthAndOnboarding = () => {
  const auth = useSelector((state: any) => state.auth);
  const user = useSelector((state: any) => state.user);
  const location = useLocation();

  return auth?.token && !user.needs_onboarding ? (
    <Outlet />
  ) : (
    <Navigate to={'/auth/login'} state={{ from: location, needs_authentication: true }} replace />
  );
};

export default RequireAuthAndOnboarding;
