import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { refreshToken } from '@/helper/ApiHelper';
import { AffiliateService } from '@/services/AffiliateService';
import { AuthLayout } from '@/components/Auth/AuthLayout';
import { BsExclamationCircle } from 'react-icons/bs';

export default function JoinAffiliate() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { token } = useParams();

  const [isConfirming, setIsConfirming] = useState(false);
  const [isIncorrect, setIsIncorrect] = useState('');

  const joinAffiliate = (token) => {
    setIsConfirming(true);
    setIsIncorrect('');

    AffiliateService.join(token)
      .then(async () => {
        setIsConfirming(false);
        setIsIncorrect('');

        await refreshToken();
        setTimeout(() => navigate('/'), 3000);
      })
      .catch((err) => {
        //createErrorNotification(err);
        setIsIncorrect(err?.response?.data?.message);
      })
      .finally(() => {
        setIsConfirming(false);
      });
  };

  useEffect(() => {
    joinAffiliate(token);
  }, []);

  return (
    <AuthLayout>
      <div className="w-[85%] max-w-[400px] mx-auto">
        <h2 className="text-2xl font-semibold py-2">{t('onboarding.join_company.title')}</h2>

        <div className={'my-6'}>
          <input type={'text'} disabled={true} value={token} className={'input w-full my-3'} />

          {isConfirming && (
            <div className={'w-full flex justify-center mt-2'}>
              <div className={'loading loading-md loading-spinner'} />
            </div>
          )}

          {!isConfirming && isIncorrect === '' && (
            <div role="alert" className="alert alert-success text-white mt-2">
              <BsExclamationCircle size={15} />
              <span>{t('onboarding.join_company.joined_successfully.message')}</span>
            </div>
          )}

          {isIncorrect !== '' && (
            <div role="alert" className="alert alert-error mt-2">
              <BsExclamationCircle size={15} />
              <span>{t('api.error.' + isIncorrect + '.body')}</span>
            </div>
          )}
        </div>

        <div className="mt-8 text-sm">
          <p>
            {t('auth.dont_have_an_account')}{' '}
            <Link to={'/auth/register'} className="hover:underline">
              {' '}
              {t('auth.register_cta')}
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}
