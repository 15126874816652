import React, { useEffect, useState } from 'react';

import { createErrorNotification } from '@/helper/ApiHelper';
import { AuthLayout } from '@/components/Auth/AuthLayout';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { User, UserService } from '@/services/UserService';
import { deleteCookie, getCookie, setCookie } from '@/helper/CookieHelper';
import { useTranslation } from 'react-i18next';

export default function RegisterPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorHighlights, setErrorHighlights] = useState([]) as any;
  const navigate = useNavigate();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    setErrorHighlights([]);

    UserService.createUser({
      first_name: firstName,
      last_name: lastName,
      email: mail,
      password: password,
      referral: getCookie('referral'),
    } as User)
      .then(() => {
        setFirstName('');
        setLastName('');
        setMail('');
        setPassword('');
        setIsDisabled(true);
        deleteCookie('referral');
        navigate('/auth/confirm-email');
      })
      .catch((err) => {
        let errors = err?.response?.data || [];
        let highlights = [];
        Object.keys(errors).forEach((errKey) => {
          if (errKey.startsWith('[') && errKey.endsWith(']')) {
            let code = errKey.replace('[', '').replace(']', '');
            highlights.push(code);
          }
        });

        setErrorHighlights(highlights);

        if (err?.response?.data?.message) {
          createErrorNotification(err);
        }
      })
      .finally(() => setIsLoading(false));
  }

  function handleChangeMail(event: React.ChangeEvent<HTMLInputElement>) {
    setMail(event.target.value.toLowerCase());
  }

  function handleChangePassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function handleChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
    setFirstName(event.target.value);
  }

  function handleChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
    setLastName(event.target.value);
  }

  useEffect(() => {
    if (searchParams.get('referral') !== null) {
      setCookie('referral', searchParams.get('referral'));
    }

    if (password !== '' && mail !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [mail, password]);

  return (
    <AuthLayout>
      <div className="w-[85%] max-w-[400px] mx-auto">
        <h2 className="text-2xl font-semibold py-2">{t('auth.register')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">{t('auth.name')}</span>
            </label>
            <div className="join join-vertical lg:join-horizontal">
              <input
                type="text"
                placeholder={t('auth.first_name')}
                className={[
                  errorHighlights.includes('firstname') ? 'border border-error' : '',
                  'input join-item input-bordered w-full',
                ].join(' ')}
                onChange={handleChangeFirstName}
                value={firstName}
              />
              <input
                type="text"
                placeholder={t('auth.last_name')}
                className={[
                  errorHighlights.includes('lastname') ? 'border border-error' : '',
                  'input join-item input-bordered w-full',
                ].join(' ')}
                onChange={handleChangeLastName}
                value={lastName}
              />
            </div>
          </div>
          <div className="form-control w-full mt-2">
            <label className="label">
              <span className="label-text">{t('auth.email')}</span>
            </label>
            <input
              type="email"
              placeholder={t('auth.your_email')}
              className={[
                errorHighlights.includes('email') ? 'border border-error' : '',
                'input input-bordered w-full',
              ].join(' ')}
              onChange={handleChangeMail}
              value={mail}
            />
          </div>
          <div className="form-control w-full mt-2">
            <label className="label">
              <span className="label-text">{t('auth.password')}</span>
            </label>
            <input
              type="password"
              placeholder={t('auth.your_password')}
              className={[
                errorHighlights.includes('password') ? 'border border-error' : '',
                'input input-bordered w-full',
              ].join(' ')}
              onChange={handleChangePassword}
              value={password}
            />
          </div>
          <div className="card-actions justify-end mt-4">
            <button
              type="submit"
              className="btn btn-primary text-white w-full disabled:text-white"
              disabled={isDisabled || isLoading}
            >
              {isLoading && <span className={'loading loading-spinner'}></span>}{' '}
              {t('auth.register')}
            </button>
          </div>
        </form>
        <div className="mt-8 text-sm">
          <p>
            {t('auth.have_an_account')}{' '}
            <Link to={'/auth/login'} className="hover:underline">
              {t('auth.login_cta')}
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}
