import React, { useEffect, useState } from 'react';

import { AuthLayout } from '@/components/Auth/AuthLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { createErrorNotification } from '@/helper/ApiHelper';
import { UserService } from '@/services/UserService';
import { useTranslation } from 'react-i18next';

export default function ValidateRegistrationPage() {
  const { t } = useTranslation();
  const [isValidating, setIsValidating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  let { token } = useParams();

  const validateRegistration = () => {
    setIsValidating(true);
    setTimeout(() => {
      UserService.verifyEmail(token as string)
        .then((res) => {
          setIsSuccess(true);
        })
        .catch((err) => {
          createErrorNotification(err);
        })
        .finally(() => {
          setIsValidating(false);
        });
    }, 2000);
  };

  useEffect(() => {
    validateRegistration();
  }, []);

  return (
    <AuthLayout>
      <div className="w-[85%] max-w-[400px] mx-auto">
        <h2 className="text-2xl font-semibold py-2">{t('auth.validate_registration')}</h2>
        {isValidating && (
          <>
            <p className="text-gray-600">{t('auth.validate_in_progress')}</p>
            <span className="loading loading-dots loading-lg mx-auto mt-3"></span>
          </>
        )}

        {!isValidating && isSuccess && (
          <div>
            <p className="text-gray-600">{t('auth.email_confirmed')}</p>
            <button
              className={'btn btn-primary text-white mt-6 w-full'}
              onClick={() => navigate('/auth/login')}
            >
              {t('general.to') + ' ' + t('auth.login_page')}
            </button>
          </div>
        )}

        {!isValidating && !isSuccess && (
          <div>
            <p>{t('auth.email_confirmation_failed')}</p>
          </div>
        )}
      </div>
    </AuthLayout>
  );
}
