import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const RequireAuth = () => {
  const auth = useSelector((state: any) => state.auth);
  const location = useLocation();

  return auth?.token ? (
    <Outlet />
  ) : (
    <Navigate to={'/auth/login'} state={{ from: location, needs_authentication: true }} replace />
  );
};

export default RequireAuth;
