import { apiRequest } from '@/helper/ApiHelper';
import { DateTime } from 'luxon';

interface Absence {
  affiliate_id: string;
  user_id: number;
  from: string;
  until: string;
  type: string;
  start?: string;
  end?: string;
  is_fullday?: boolean;
  comment?: string;
}

export const AbsenceService = {
  createAbsence: async (absence: Absence) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/absence',
        method: 'POST',
        data: absence,
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getAbsence: async (absenceId) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/absence/' + absenceId,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  deleteAbsence: async (absenceId: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/absence',
        method: 'DELETE',
        data: {
          absence_request_id: absenceId,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  validateAbsence: async (absenceId: string, status: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/absence/validate',
        method: 'POST',
        data: {
          absence_request_id: absenceId,
          status: status,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  updateAbsence: async (absenceId: string, comment: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/absence/' + absenceId,
        method: 'PATCH',
        data: {
          comment: comment,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getAbsenceCalendar: async (
    affiliate_id: string,
    start: string,
    end: string,
    user_id?: string,
  ) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/absence/calendar',
        data: {
          affiliate_id: affiliate_id,
          user_id: user_id,
          start: start,
          end: end,
          timezone: DateTime.local().toFormat('z'),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getAbsencePeriod: async (affiliate_id: string, start: string, end: string, user_id?: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/absence/period',
        data: {
          affiliate_id: affiliate_id,
          user_id: user_id,
          start: start,
          end: end,
          timezone: DateTime.local().toFormat('z'),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
