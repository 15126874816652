import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { ShiftService } from '@/services/ShiftService';
import { StampService } from '@/services/StampService';

interface FinishStampModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  clearClock: any;
}

export const FinishStampModal = ({
  isModalOpen,
  setIsModalOpen,
  clearClock,
}: FinishStampModalProps) => {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const user = useSelector((state: any) => state.user);
  const [projects, setProjects] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const initialShift = {
    user_id: user.id,
    note: '',
    project_id: '',
  };

  const [shift, setShift] = useState({ ...initialShift });
  const setShiftData = (evt) => {
    setShift({ ...shift, [evt.target.name]: evt.target.value });
  };

  const createShift = () => {
    setIsSaving(true);

    StampService.stamp(selectedAffiliate.id, shift.note, shift.project_id)
      .then(() => {
        let today = DateTime.now();
        createNotification(
          t('time_tracking.notifications.add_time_header'),
          t('time_tracking.notifications.add_time_message', {
            date: today.toFormat('dd.LL.'),
          }),
          'success',
        );
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setShift({ ...initialShift });
        setIsSaving(false);
        setIsModalOpen(false);
        clearClock();
      });
  };

  useEffect(() => {
    ShiftService.getModal(selectedAffiliate.id)
      .then((res: any) => {
        let user: any =
          res.filter((user: any) => {
            return user.id === parseInt(user.id);
          })[0] ?? [];
        setProjects(user?.projects ? Object.values(user.projects) : []);
        setShift({ ...shift, user_id: user.id });
      })
      .catch((err) => {
        createErrorNotification(err);
      });
  }, []);

  return (
    <Modal
      headline={t('time_tracking.working_time')}
      onSafe={createShift}
      modalName="create_stamp_modal"
      isOpen={isModalOpen}
      isLoading={isSaving}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className={'form-control w-full ' + (projects.length === 0 ? 'hidden' : '')}>
        <label className="label">
          <span className="label-text">{t('general.project')}</span>
        </label>
        <select
          name={'project_id'}
          value={shift.project_id}
          onChange={setShiftData}
          className="select select-bordered w-full max-w"
        >
          <option value={''}>{t('general.please_select')}</option>
          {projects.map((project: any, key: number) => {
            return (
              <option key={key} value={project.id}>
                {project.title}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{t('general.note')}</span>
        </label>
        <textarea
          name={'note'}
          onChange={setShiftData}
          className="textarea textarea-bordered h-10"
        />
      </div>
    </Modal>
  );
};
