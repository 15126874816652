import React from 'react';
import { t } from 'i18next';
import { FaUmbrellaBeach } from 'react-icons/fa';
import { BsClock } from 'react-icons/bs';

export const SlotAccordion = ({ slots, openDetails, groupSlots = true }) => {
  return (
    <div className="join join-vertical w-full lg:p-6">
      {slots &&
        slots.map((shift, key) => {
          return (
            <div key={key} className="collapse join-item border border-base-300">
              <input type="radio" name="calendar_slot_overview" defaultChecked={key === 0} />

              <div className="collapse-title text-xl font-medium">
                <div className="flex items-center justify-between">
                  <p>{shift.user.name}</p>
                  <div className="badge badge-default p-3 text-md">
                    {shift.is_absence === true ? (
                      <span className={'px-2'}>
                        {t(
                          'general.' +
                            (shift.is_validated
                              ? shift.is_accepted
                                ? 'confirmed'
                                : 'declined'
                              : 'open'),
                        )}
                      </span>
                    ) : (
                      <span className={'px-2'}>
                        {shift.duration + ' ' + t('time_tracking.hours')}
                      </span>
                    )}
                    {shift.is_absence ? (
                      <FaUmbrellaBeach
                        size={17}
                        className={
                          shift.is_validated
                            ? shift.is_accepted
                              ? 'text-success'
                              : 'text-error'
                            : 'text-warning'
                        }
                      />
                    ) : (
                      <BsClock size={17} />
                    )}
                  </div>
                </div>
              </div>

              <div className="collapse-content">
                <div className="grid grid-cols-3 stats w-full rounded-none">
                  <div className="stat place-items-center">
                    <div className="stat-title overflow-hidden">
                      {t('time_tracking.working_time')}
                    </div>
                    <div className="lg:text-3xl text-2xl font-bold pt-3 lg:pt-2">
                      {shift.duration}
                    </div>
                    <div className="stat-desc">{t('time_tracking.hours')}</div>
                  </div>
                  <div className="stat place-items-center">
                    <div className="stat-title">{t('time_tracking.break')}</div>
                    <div className="lg:text-3xl text-2xl font-bold pt-3 lg:pt-2">{shift.pause}</div>
                    <div className="stat-desc">{t('time_tracking.minutes')}</div>
                  </div>
                  <div className="stat place-items-center">
                    <div className="stat-title">{t('time_tracking.total')}</div>
                    <div className="lg:text-3xl text-2xl font-bold pt-3 lg:pt-2">{shift.total}</div>
                    <div className="stat-desc">{t('time_tracking.hours')}</div>
                  </div>
                </div>
                <div className={'flex justify-end'}>
                  <div
                    className={'btn btn-ghost'}
                    onClick={() => openDetails(shift.user.id, shift.is_absence)}
                  >
                    Details
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
