import { apiRequest } from '@/helper/ApiHelper';

export interface User {
  user_id?: number;
  affiliate_id?: number;
  first_name: string;
  last_name: string;
  name: string;
  email?: string;
  password?: string;
  start?: string;
  end?: string;
  referral?: string;
}

export const UserService = {
  createUser: async (user: User) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/user',
        publicRequest: user.affiliate_id === undefined,
        responseType: 'json',
        data: {
          affiliate_id: user.affiliate_id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          password: user.password,
          start: user.start,
          end: user.end,
          referral: user?.referral,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  verifyEmail: async (token: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/user/verify/email/' + token,
        method: 'POST',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getDashboard: async () => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/user/dashboard',
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  resetPassword: async (email) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/user/password/reset',
        method: 'POST',
        data: {
          email: email,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  setNewPassword: async (token, password) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/user/password/new',
        method: 'POST',
        data: {
          token: token,
          password: password,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
