import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStep, setSubscription } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';
import { OnboardingLayout } from '@/components/Onboarding/OnboardingLayout';
import { OnboardingPackageCard } from '@/components/Onboarding/OnboardingPackageCard';
import { Button } from '@/components/ui/button';
import { PaymentService } from '@/services/PaymentService';
import { License } from '@/types/licensing/Licensing';
import { Slider } from '@/components/ui/slider';

export default function Step_LicenseSelection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const subscription = useSelector((state: any) => state.onboarding.subscription);
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState<any | null>(null);

  useEffect(() => {
    dispatch(setStep(4));
    fetchPrices();
  }, []);

  const setLicense = (type: License) => {
    dispatch(setSubscription({ ...subscription, type }));
  };

  const setNumberEmployees = (number_employees: number) => {
    dispatch(setSubscription({ ...subscription, number_employees }));
  };

  const previousStep = () => {
    navigate('/onboarding/create-affiliate');
  };

  const nextStep = () => {
    if (subscription.type === License.ENTERPRISE) {
      navigate('/onboarding/confirm');
    } else {
      navigate('/onboarding/add-payment');
    }
  };

  const fetchPrices = () => {
    setIsLoading(true);
    PaymentService.getPrices()
      .then((pricesData: any) => {
        console.log('Raw API response:', pricesData);
        setPrices(pricesData);
      })
      .catch((err) => console.error('Fehler beim Abrufen der Preise:', err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const calculatePrice = () => {
    if (!prices || !subscription.type || !subscription.number_employees) return 0;
    const priceData = prices[subscription.type];
    return priceData ? priceData[subscription.number_employees]?.flat_amount / 100 : 0;
  };

  return (
    <OnboardingLayout sidebar={sidebarContent()} showSidebarOnMobile>
      <div className="w-full">
        <div className="mb-6 flex justify-between items-center">
          <div>
            <h2 className="text-2xl text-primary font-bold">
              {t('onboarding.header.choose_license')}
            </h2>
            <span className="text-gray-700">{t('onboarding.choose_license.subtitle')}</span>
          </div>
          <Button
            variant={'link'}
            onClick={() => {
              previousStep();
            }}
          >
            {t('general.back')}
          </Button>
        </div>

        <div>
          <OnboardingPackageCard
            title="Team"
            subtitle={t('onboarding.choose_license.team.subtitle')}
            featureList={[
              t('onboarding.choose_license.team.feature_1'),
              t('onboarding.choose_license.team.feature_2'),
              t('onboarding.choose_license.team.feature_3'),
              t('onboarding.choose_license.team.feature_4'),
              t('onboarding.choose_license.team.feature_5'),
            ]}
            selected={subscription.type === License.TEAM}
            onClick={() => setLicense(License.TEAM)}
          />
          <OnboardingPackageCard
            title="Business"
            subtitle={t('onboarding.choose_license.business.subtitle')}
            featureList={[
              t('onboarding.choose_license.business.feature_1'),
              t('onboarding.choose_license.business.feature_2'),
              t('onboarding.choose_license.business.feature_3'),
              t('onboarding.choose_license.business.feature_4'),
            ]}
            selected={subscription.type === License.BUSINESS}
            onClick={() => setLicense(License.BUSINESS)}
          />
          <OnboardingPackageCard
            title="Enterprise"
            subtitle={t('onboarding.choose_license.enterprise.subtitle')}
            featureList={[
              t('onboarding.choose_license.business.feature_1'),
              t('onboarding.choose_license.business.feature_2'),
              t('onboarding.choose_license.business.feature_3'),
            ]}
            selected={subscription.type === License.ENTERPRISE}
            onClick={() => setLicense(License.ENTERPRISE)}
          />
        </div>
      </div>
    </OnboardingLayout>
  );

  function sidebarContent(): ReactNode {
    const indexToUserMapping = {
      0: 5,
      1: 10,
      2: 20,
      3: 30,
      4: 50,
    };

    const licenseTitles: { [key in License]: string } = {
      [License.TEAM]: t('onboarding.choose_license.license_names.team'),
      [License.BUSINESS]: t('onboarding.choose_license.license_names.business'),
      [License.ENTERPRISE]: t('onboarding.choose_license.license_names.enterprise'),
    };

    const currentLicenseTitle = licenseTitles[subscription.type] || '';

    const buttonText =
      subscription.type === License.ENTERPRISE
        ? t('onboarding.choose_license.cta.order')
        : t('onboarding.choose_license.cta.start');

    return (
      <div className="text-white pt-8">
        <div className="mb-4">
          <h2 className="text-2xl text-left font-bold flex">
            {t('onboarding.choose_license.configurator.employee_number')}
          </h2>
          <p className="text-left">{t('onboarding.choose_license.configurator.description')}</p>
        </div>
        <Slider
          min={0}
          max={4}
          defaultValue={[subscription.number_employees]}
          onValueChange={(value) => setNumberEmployees(value)}
        />
        <div className="w-full flex justify-between text-xs px-2 mt-2">
          <span>5</span>
          <span>10</span>
          <span>20</span>
          <span>30</span>
          <span>50</span>
        </div>

        <div className="bg-white w-full rounded-t-lg lg:rounded-lg shadow-md mt-12 text-gray-700 px-4 py-4">
          <div className="flex justify-between items-center ">
            <div className="text-left">
              <h2 className="text-2xl font-bold">{currentLicenseTitle}</h2>
              <p>
                {indexToUserMapping[subscription.number_employees]}
                {' ' + t('onboarding.choose_license.configurator.employee')}
              </p>
            </div>
            <div className="grid">
              <span className="text-2xl font-bold text-right">
                {!prices ? (
                  <span className={'loading loading-sm loading-spinner'}></span>
                ) : (
                  calculatePrice() + ',00'
                )}{' '}
                €
              </span>
              <span className="text-sm text-right text-gray-600">
                {t('onboarding.choose_license.configurator.monthly_payment')}
              </span>
            </div>
          </div>
          {subscription.type === License.ENTERPRISE && (
            <>
              <div className="flex justify-between items-center mt-4">
                <div className="text-left">
                  <h2 className="text-xl font-bold">
                    {t('onboarding.choose_license.configurator.card_reader')}
                  </h2>
                  <p>{t('onboarding.choose_license.configurator.card_reader_desc')}</p>
                </div>
                <div className="grid">
                  <span className="text-xl font-bold text-right">55,00 €</span>
                </div>
              </div>
              <div className="flex justify-between items-center mt-3">
                <div className="text-left">
                  <h2 className="text-xl font-bold">
                    {t('onboarding.choose_license.configurator.cards')}
                  </h2>
                  <p>
                    {indexToUserMapping[subscription.number_employees] +
                      ' ' +
                      t('onboarding.choose_license.configurator.cards_desc')}
                  </p>
                </div>
                <div className="grid">
                  <span className="text-xl font-bold text-right">
                    {indexToUserMapping[subscription.number_employees]},00 €
                  </span>
                  <span className="text-sm text-right text-gray-600">
                    {t('onboarding.choose_license.configurator.single_time_payment')}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="mt-4 text-left text-primary flex justify-between items-center">
            <p className="font-bold">{t('onboarding.choose_license.configurator.trial')}</p>
            <Button onClick={nextStep}>{buttonText}</Button>
          </div>
        </div>
      </div>
    );
  }
}
