// BlogPostsCarousel.tsx
import { useBlogPosts } from '@/integrations/useBlogPosts';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';

export function BlogPostsCarousel() {
  const { data, isLoading } = useBlogPosts();

  if (isLoading) {
    return (
      <div className="p-6">
        <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-3">
          {isLoading &&
            [1, 2, 3].map((value, index) => (
              <div key={index} className={'relative lg:max-w-xl mx-auto w-full h-full'}>
                <div className={'absolute inset-0 bg-secondary opacity-50 rounded-md'}></div>
                <div className={'flex items-center justify-start p-5 h-full'}>
                  <div className={'w-40 h-12 bg-white opacity-20 rounded'}></div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="w-full grid lg:grid-cols-2 xl:grid-cols-3 gap-3">
        {isLoading &&
          [1, 2, 3, 4].map((value, index) => (
            <div key={index} className={'relative lg:max-w-xl mx-auto w-full h-full'}>
              <div className={'absolute inset-0 bg-secondary opacity-50 rounded-md'}></div>
              <div className={'flex items-center justify-start p-5 h-full'}>
                <div className={'w-40 h-12 bg-white opacity-20 rounded'}></div>
              </div>
            </div>
          ))}

        {data.map((post: any, key) => {
          if (key < 3) {
            return (
              <div key={key}>
                <div className={'lg:max-w-xl mx-auto'}>
                  <Link target="_blank" to={post.link}>
                    <img
                      className={'w-full object-cover rounded-t-md'}
                      src={post._embedded['wp:featuredmedia'][0].source_url}
                      alt={post.title.rendered}
                    />
                    <div className="border border-gray-200 rounded-b-md">
                      <div className="flex items-center justify-between px-3">
                        <div className="rounded-b-md py-2">
                          <p className={'text-secondary text-lg font-bold'}>
                            {post.title.rendered}
                          </p>
                          <p className="text-gray-400">
                            {DateTime.fromISO(post.date).toLocaleString({
                              day: 'numeric',
                              year: 'numeric',
                              month: 'long',
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}
