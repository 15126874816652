import { apiRequest } from '@/helper/ApiHelper';

export const StampService = {
  getLast: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/stamp/last',
        method: 'POST',
        data: {
          affiliate_id,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  stamp: async (affiliate_id: string, note: string = '', project_id: string = '') => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/stamp',
        method: 'POST',
        data: {
          affiliate_id: affiliate_id,
          note: note,
          project_id: project_id,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
