import React from 'react';
import { UserConfigurationDynamicTable } from '@/components/Tables/UserConfigurationDynamicTable';
import { UserConfigurationDynamic } from '@/types/User/UserConfiguration';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { BsExclamationCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { mutateDynamicUserConfiguration } from '@/integrations/useUserConfiguration';
import { exists } from 'i18next';

export interface EditUserConfigurationFormProps {
  configs: UserConfigurationDynamic[];
  userId: string | undefined;
  refetch: () => void;
}

const EditUserConfigurationDynamicForm: React.FC<EditUserConfigurationFormProps> = ({
  refetch,
  userId,
  configs,
}) => {
  const affiliateId = useSelector((state: any) => state.affiliate.selectedAffiliate.id);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    mutate: updateConfigMutation,
    error,
    isError,
    isPending,
  } = mutateDynamicUserConfiguration(queryClient, t);

  const handleSubmit = async (configs: UserConfigurationDynamic[]) => {
    updateConfigMutation({ affiliateId, userId, data: configs });
  };

  let errorMessage = t('api.error.INVALID_PARAMETER.body');
  if (exists('api.error.' + error?.response?.data.message + '.body')) {
    errorMessage = t('api.error.' + error?.response?.data.message + '.body');
  }

  return (
    <div className="">
      <div className="prose px-6">
        <h3>{t('profile_page.user_config')}</h3>
      </div>

      {isError && (
        <div role="alert" className="alert alert-error m-6 w-fit">
          <BsExclamationCircle size={20} className="text-base-500" />
          <span>{errorMessage}</span>
        </div>
      )}

      <UserConfigurationDynamicTable
        isPending={isPending}
        cancelConfig={refetch}
        configs={configs}
        saveConfig={handleSubmit}
      />
    </div>
  );
};

export default EditUserConfigurationDynamicForm;
