import React from 'react';
import { BsFillRocketTakeoffFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const UpgradeSubscriptionBanner = ({ addon }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={
        'bg-base-200 bg-opacity-50 flex justify-center items-center absolute top-0 left-0 bottom-0 right-0 z-10'
      }
    >
      <div className={'bg-white p-8 rounded mb-40 max-w-4xl'}>
        <h1 className={'text-xl font-bold'}>{t('commerce.upgrade.' + addon + '.title')}</h1>
        <p className={'mt-1'}>{t('commerce.upgrade.' + addon + '.message')}</p>

        <div className={'flex justify-center mt-4'}>
          <div
            className={'mx-auto btn btn-primary text-white'}
            onClick={() => navigate('/settings/license')}
          >
            <BsFillRocketTakeoffFill size={17} /> {t('commerce.upgrade_now')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeSubscriptionBanner;
