import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { apiRequest, createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { ShiftService } from '@/services/ShiftService';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const DeleteShiftModal = ({
  isModalOpen,
  setIsModalOpen,
  isAbsence,
  shiftId,
  date,
  reload,
  userName,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const { t } = useTranslation();

  useEffect(() => {}, [shiftId]);

  const deleteShift = () => {
    setIsSaving(true);

    let url = '/api/shift/';
    if (isAbsence) {
      url = '/api/absence/';
    }

    ShiftService.deleteShift(shiftId)
      .then(async () => {
        createNotification(
          t('time_tracking.notifications.delete_time_header'),
          t('time_tracking.notifications.delete_time_message', {
            date: date,
            userName: userName,
          }),
          'success',
        );
        await reload();
        setIsModalOpen(false);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Modal
      modalName="delete_shift"
      headline={t('time_tracking.modals.delete_time_header')}
      isOpen={isModalOpen}
      isLoading={isSaving}
      disableSafe={selectedAffiliate?.isAdmin === false}
      primaryButtonText={'delete'}
      primaryButtonClass={'btn-error'}
      onSafe={deleteShift}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="space-y-1">
        <p className="w-full">
          {t('time_tracking.modals.delete_time_message', {
            date: date,
            userName: userName,
          })}
        </p>
      </div>
    </Modal>
  );
};
