import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { UserConfigurationDynamic } from '@/types/User/UserConfiguration';
import { useTranslation } from 'react-i18next';

interface EditEmployeeSettingsModalProps {
  initialConfig: UserConfigurationDynamic | null;
  setIsModalOpen: (any) => void;
  isModalOpen: boolean;
  saveConfig: (config: UserConfigurationDynamic) => void;
}

export const EditEmployeeSettingsModal: React.FC<EditEmployeeSettingsModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  initialConfig,
  saveConfig,
}) => {
  const [config, setConfig] = useState<UserConfigurationDynamic | null>(null);
  const [extendedConfiguration, setExtendedConfiguration] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setConfig(initialConfig);
  }, [initialConfig]);

  const setWeeklyHours = (hours: number) => {
    setConfig({
      ...config,
      weekly_hours_configuration: {
        monday: Math.round((hours * 100) / 5) / 100,
        tuesday: Math.round((hours * 100) / 5) / 100,
        wednesday: Math.round((hours * 100) / 5) / 100,
        thursday: Math.round((hours * 100) / 5) / 100,
        friday: Math.round((hours * 100) / 5) / 100,
        saturday: 0,
        sunday: 0,
      },
    } as UserConfigurationDynamic);
  };

  if (config === null || config === undefined) {
    return;
  }

  const totalWeeklyHours =
    Math.round(
      Object.values(config.weekly_hours_configuration).reduce(
        (partialSum, a) => partialSum + a,
        0,
      ) * 100,
    ) / 100;

  return (
    <Modal
      modalName="edit_employee"
      headline={t('profile_page.modals.edit_employee_header')}
      isOpen={isModalOpen}
      isLoading={false}
      primaryButtonText="apply_change"
      disableSafe={false}
      onSafe={() => saveConfig(config)}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="space-y-1">
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">
              {t('profile_page.modals.edit_employee_hoursPerWeek')}
            </span>
            <span className="label-text-alt">
              <label className="label p-0 cursor-pointer gap-2">
                <span className="label-text text-xs text-info">{t('menu.extended_settings')}</span>
                <input
                  type="checkbox"
                  className="toggle toggle-info toggle-xs"
                  checked={extendedConfiguration}
                  onChange={() => setExtendedConfiguration(!extendedConfiguration)}
                />
              </label>
            </span>
          </label>
          {!extendedConfiguration ? (
            <input
              type="number"
              step={'0.1'}
              name={'weekly_hours'}
              onChange={(evt) => setWeeklyHours(parseFloat(evt.target.value))}
              value={totalWeeklyHours}
              placeholder=""
              className="input input-bordered w-full"
            />
          ) : (
            <div>
              <div className={'join w-full mb-2'}>
                <div className="label">
                  <span className="label-text w-28">{t('time_tracking.total')}</span>
                </div>
                <input
                  type="number"
                  step={'0.1'}
                  name={'weekly_hours'}
                  disabled={true}
                  value={totalWeeklyHours}
                  placeholder=""
                  className="input input-bordered w-full"
                />
              </div>
              <div className={'join w-full mb-2'}>
                <div className="label">
                  <span className="label-text w-28">{t('general.day.monday')}</span>
                </div>
                <input
                  placeholder={'7,5'}
                  min={0}
                  type={'number'}
                  step={'0.1'}
                  defaultValue={0}
                  value={config?.weekly_hours_configuration.monday}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      weekly_hours_configuration: {
                        ...config?.weekly_hours_configuration,
                        monday: parseFloat(e.target.value),
                      },
                    })
                  }
                  className="input input-md input-bordered w-full"
                />
              </div>
              <div className={'join w-full mb-2'}>
                <div className="label">
                  <span className="label-text w-28">{t('general.day.tuesday')}</span>
                </div>
                <input
                  placeholder={'7,5'}
                  type={'number'}
                  step={'0.1'}
                  value={config?.weekly_hours_configuration.tuesday}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      weekly_hours_configuration: {
                        ...config?.weekly_hours_configuration,
                        tuesday: parseFloat(e.target.value),
                      },
                    })
                  }
                  className="input input-md input-bordered w-full"
                />
              </div>
              <div className={'join w-full mb-2'}>
                <div className="label">
                  <span className="label-text w-28">{t('general.day.wednesday')}</span>
                </div>
                <input
                  placeholder={'7,5'}
                  type={'number'}
                  step={'0.1'}
                  value={config?.weekly_hours_configuration.wednesday}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      weekly_hours_configuration: {
                        ...config?.weekly_hours_configuration,
                        wednesday: parseFloat(e.target.value),
                      },
                    })
                  }
                  className="input input-md input-bordered w-full"
                />
              </div>
              <div className={'join w-full mb-2'}>
                <div className="label">
                  <span className="label-text w-28">{t('general.day.thursday')}</span>
                </div>
                <input
                  placeholder={'7,5'}
                  type={'number'}
                  step={'0.1'}
                  value={config?.weekly_hours_configuration.thursday}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      weekly_hours_configuration: {
                        ...config?.weekly_hours_configuration,
                        thursday: parseFloat(e.target.value),
                      },
                    })
                  }
                  className="input input-md input-bordered w-full"
                />
              </div>
              <div className={'join w-full mb-2'}>
                <div className="label">
                  <span className="label-text w-28">{t('general.day.friday')}</span>
                </div>
                <input
                  placeholder={'7,5'}
                  type={'number'}
                  step={'0.1'}
                  value={config?.weekly_hours_configuration.friday}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      weekly_hours_configuration: {
                        ...config?.weekly_hours_configuration,
                        friday: parseFloat(e.target.value),
                      },
                    })
                  }
                  className="input input-md input-bordered w-full"
                />
              </div>
              <div className={'join w-full mb-2'}>
                <div className="label">
                  <span className="label-text w-28">{t('general.day.saturday')}</span>
                </div>
                <input
                  placeholder={'7,5'}
                  type={'number'}
                  step={'0.1'}
                  value={config?.weekly_hours_configuration.saturday}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      weekly_hours_configuration: {
                        ...config?.weekly_hours_configuration,
                        saturday: parseFloat(e.target.value),
                      },
                    })
                  }
                  className="input input-md input-bordered w-full"
                />
              </div>
              <div className={'join w-full mb-2'}>
                <div className="label">
                  <span className="label-text w-28">{t('general.day.sunday')}</span>
                </div>
                <input
                  placeholder={'7,5'}
                  type={'number'}
                  step={'0.1'}
                  value={config?.weekly_hours_configuration.sunday}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      weekly_hours_configuration: {
                        ...config?.weekly_hours_configuration,
                        sunday: parseFloat(e.target.value),
                      },
                    })
                  }
                  className="input input-md input-bordered w-full"
                />
              </div>
            </div>
          )}
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">
              {t('profile_page.modals.edit_employee_vacation_days')}
            </span>
          </label>
          <input
            type="number"
            step={'0.1'}
            name={'vacation_days'}
            onChange={(evt) =>
              setConfig({ ...config, vacation_days: parseFloat(evt.target.value) })
            }
            value={config?.vacation_days}
            placeholder=""
            className="input input-bordered w-full"
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">{t('profile_page.modals.edit_employee_valid_from')}</span>
          </label>
          <input
            type="date"
            name={'valid_from'}
            onChange={(evt) => setConfig({ ...config, valid_from: evt.target.value })}
            value={config?.valid_from}
            placeholder=""
            className="input input-bordered w-full"
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">{t('profile_page.modals.edit_employee_valid_until')}</span>
          </label>
          <input
            type="date"
            name={'valid_until'}
            onChange={(evt) => setConfig({ ...config, valid_until: evt.target.value })}
            value={config?.valid_until}
            placeholder=""
            className="input input-bordered w-full"
          />
        </div>
      </div>
    </Modal>
  );
};
