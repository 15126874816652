import { useQuery } from '@tanstack/react-query';
import { AffiliateService } from '@/services/AffiliateService';
import { UserResponse } from '@/types/User/UserConfiguration';

export const useUserProfile = (selectedAffiliateId: string, userId: string) => {
  return useQuery<unknown, Error, UserResponse>({
    queryKey: ['profile-page', selectedAffiliateId, userId],
    queryFn: () => AffiliateService.getUserProfile(selectedAffiliateId, userId),
  });
};
