import React from 'react';
import { t } from 'i18next';

interface ModalProps {
  isOpen: boolean;
  onSafe: () => void;
  onClose: () => void;
  modalName: string;
  headline: string;
  children: React.ReactNode;
  disableSafe?: boolean;
  showDeleteButton?: boolean;
  onDelete?: () => void;
  isLoading?: boolean;
  primaryButtonText?: string;
  primaryButtonClass?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSafe,
  modalName,
  headline,
  children,
  primaryButtonText = 'save',
  primaryButtonClass = 'btn-primary',
  isLoading = false,
  disableSafe = false,
  showDeleteButton = false,
  onDelete = () => {},
}) => {
  return (
    <div>
      <input
        type="checkbox"
        id={modalName}
        className="modal-toggle"
        checked={isOpen}
        onChange={onClose}
      />
      <div className={`modal modal-bottom sm:modal-middle ${isOpen ? 'active' : ''}`}>
        <div className="modal-box">
          <h3 className="font-bold text-lg">{headline}</h3>
          <div className="py-4">{children}</div>
          <div className="modal-action justify-end">
            {showDeleteButton && (
              <label className="btn btn-ghost text-error mr-auto" onClick={onDelete}>
                {t('general.delete')}
              </label>
            )}

            <div className={'flex justify-end gap-4'}>
              <label htmlFor={modalName} className="btn" onClick={onClose}>
                {t('general.cancel')}
              </label>
              <label
                className={
                  disableSafe || isLoading
                    ? 'btn text-white btn-disabled ' + primaryButtonClass
                    : 'btn text-white ' + primaryButtonClass
                }
                onClick={onSafe}
              >
                {isLoading && <span className="loading loading-spinner mr-2"></span>}
                {t('general.' + primaryButtonText)}&nbsp;
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
