import React, { useState } from 'react';
import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { User, UserService } from '@/services/UserService';
import Modal from '../General/Modal';

export const CreateEmployeeModal = ({ isModalOpen, setIsModalOpen, reloadUser }) => {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    start_date: DateTime.now().plus({ month: 1 }).startOf('month').toFormat('y-LL-dd'),
    end_date: '',
  });

  const createOfflineUser = () => {
    setIsLoading(true);
    let startDate = DateTime.fromSQL(user.start_date).toISO({
      suppressMilliseconds: true,
    });
    let endDate = DateTime.fromSQL(user.end_date).toISO({
      suppressMilliseconds: true,
    });

    const data = {
      affiliate_id: selectedAffiliate.id,
      first_name: user.firstname,
      last_name: user.lastname,
      start: startDate,
      end: endDate,
    };

    UserService.createUser(data as User)
      .then(async () => {
        createNotification(
          'Mitarbeiter erstellt',
          user.firstname + ' ' + user.lastname + ' wurde hinzugefügt.',
          'success',
        );
        await reloadUser();
        setIsModalOpen(false);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setUserData = (evt) => {
    setUser({ ...user, [evt.target.name]: evt.target.value });
  };

  return (
    <Modal
      isLoading={isLoading}
      modalName="create_employee"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onSafe={createOfflineUser}
      headline={t('employee_page.create_employee')}
    >
      <AddEmployeeContent user={user} setUserData={setUserData} />
    </Modal>
  );
};

const AddEmployeeContent = ({ user, setUserData }) => {
  return (
    <div className="space-y-1">
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{t('general.firstname')}</span>
        </label>
        <input
          type="text"
          name={'firstname'}
          value={user.firstname}
          onChange={setUserData}
          placeholder={t('general.firstname')}
          className="input input-bordered w-full"
        />
      </div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{t('general.lastname')}</span>
        </label>
        <input
          type="text"
          name={'lastname'}
          value={user.lastname}
          onChange={setUserData}
          placeholder={t('general.lastname')}
          className="input input-bordered w-full"
        />
      </div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{t('general.start_of_employment')}</span>
        </label>
        <input
          type="date"
          name={'start_date'}
          value={user.start_date}
          onChange={setUserData}
          placeholder={t('general.start_of_employment')}
          className="input input-bordered w-full"
        />
      </div>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">{t('general.end_of_employment')}</span>
        </label>
        <input
          type="date"
          name={'end_date'}
          value={user.end_date}
          onChange={setUserData}
          placeholder={t('general.end_of_employment')}
          className="input input-bordered w-full"
        />
      </div>
    </div>
  );
};
