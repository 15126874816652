import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import logo from '@/assets/logo_wp.png';
import { BsClock, BsGear, BsHouse, BsShop } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser as clearAuth } from '@/global/authSlice';
import { clearState as clearUser } from '@/global/userSlice';
import { clearState as clearAffiliate, selectAffiliate } from '@/global/affiliateSlice';
import { useTranslation } from 'react-i18next';

const AppLayout: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const language = i18n.language || window.localStorage.i18nextLng;
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const affiliates = useSelector((state: any) => state.affiliate.affiliates);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const logout = () => {
    setIsMenuOpen(false);
    dispatch(clearUser());
    dispatch(clearAffiliate());
    dispatch(clearAuth());
  };

  const changeLanguage = (evt) => {
    i18n.changeLanguage(evt.target.value);
  };

  return (
    <div className="drawer lg:drawer-open h-full">
      <input
        id="workplan-drawer"
        type="checkbox"
        checked={isMenuOpen}
        onChange={() => {}}
        className="drawer-toggle"
      />
      <div className="drawer-content w-full min-h-screen">
        <div className="navbar bg-base-100 border-b border-base-300 rounded-none lg:hidden">
          <div className="navbar-start">
            <div className="dropdown" onClick={() => setIsMenuOpen(true)}>
              <label htmlFor="my-drawer" className="drawer-button lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h7"
                  />
                </svg>
              </label>
            </div>
          </div>
          <div className="navbar-center lg:hidden">
            <Link to={'/'}>
              <img alt={'Workplan.Digital Logo'} src={logo} className="w-10" />
            </Link>
          </div>
          <div className="navbar-end"></div>
        </div>
        <main className={'w-full h-full'}>
          <Outlet />
        </main>
      </div>
      <div className="drawer-side z-20">
        <label
          htmlFor="my-drawer"
          className="drawer-overlay"
          onClick={() => setIsMenuOpen(false)}
        ></label>
        <ul className="menu p-4 w-80 min-h-full bg-base-100 border-r border-base-300 text-base-content">
          <li className="mb-5">
            <Link to={'/'}>
              <img src={logo} className={'w-10'} />{' '}
              <span className={'text-lg'}>Workplan.Digital</span>
            </Link>
          </li>

          {Object.values(affiliates).length > 1 && (
            <>
              <li className="menu-title flex flex-row gap-2">
                <span className="text-base-content">
                  <BsShop size={17} color={'gray'} />
                </span>
                <span>{t('menu.current_company')}</span>
              </li>
              <li>
                <select
                  className="select select-bordered w-full mb-4"
                  defaultValue={selectedAffiliate.id}
                  onChange={async (e) => {
                    await dispatch(selectAffiliate(e.target.value));
                    window.location.reload();
                  }}
                >
                  {Object.values(affiliates).map((affiliate: any) => (
                    <option value={affiliate.id}>{affiliate.title}</option>
                  ))}
                </select>
              </li>
            </>
          )}

          <li className="menu-title flex flex-row gap-2">
            <span className="text-base-content">
              <BsHouse size={17} color={'gray'} />
            </span>
            <span>{t('menu.company')}</span>
          </li>
          <li>
            <Link
              to={'/'}
              onClick={() => setIsMenuOpen(false)}
              className={location.pathname === '/' ? 'active' : ''}
            >
              {t('menu.dashboard')}
            </Link>
          </li>
          <li>
            <Link
              to={'/affiliate/employees'}
              onClick={() => setIsMenuOpen(false)}
              className={location.pathname === '/affiliate/employees' ? 'active' : ''}
            >
              {t('menu.employees')}
            </Link>
          </li>

          <li></li>
          <li className="menu-title flex flex-row gap-2">
            <span className="text-base-content">
              <BsClock size={17} color={'gray'} />
            </span>
            <span>{t('menu.time_tracking')}</span>
          </li>
          <li>
            <Link
              to={'/time/overview'}
              onClick={() => setIsMenuOpen(false)}
              className={location.pathname === '/time/overview' ? 'active' : ''}
            >
              {t('menu.time_overview')}
            </Link>
          </li>
          <li>
            <Link
              to={'/time/calendar'}
              onClick={() => setIsMenuOpen(false)}
              className={location.pathname === '/time/calendar' ? 'active' : ''}
            >
              {t('menu.time_calendar')}
            </Link>
          </li>
          <li>
            <Link
              to={'/time/absence'}
              onClick={() => setIsMenuOpen(false)}
              className={location.pathname === '/time/absence' ? 'active' : ''}
            >
              {t('menu.absence')}
            </Link>
          </li>

          {selectedAffiliate.isAdmin && (
            <>
              <li>
                <Link
                  to={'/time/projects'}
                  onClick={() => setIsMenuOpen(false)}
                  className={location.pathname === '/time/projects' ? 'active' : ''}
                >
                  {t('menu.projects')}
                </Link>
              </li>
              <li>
                <Link
                  to={'/time/export'}
                  onClick={() => setIsMenuOpen(false)}
                  className={location.pathname === '/time/export' ? 'active' : ''}
                >
                  {t('menu.export')}
                </Link>
              </li>
            </>
          )}
          <li></li>

          {selectedAffiliate.isAdmin && (
            <>
              <li className="menu-title flex flex-row gap-2">
                <span className="text-base-content">
                  <BsGear size={17} color={'gray'} />
                </span>
                <span>{t('menu.settings')}</span>
              </li>
              <li>
                <Link
                  to={'/settings/license'}
                  onClick={() => setIsMenuOpen(false)}
                  className={location.pathname === '/settings/license' ? 'active' : ''}
                >
                  Abonnement
                </Link>
              </li>
              <li>
                <Link
                  to={'/settings/general'}
                  onClick={() => setIsMenuOpen(false)}
                  className={location.pathname === '/settings/general' ? 'active' : ''}
                >
                  {t('menu.general_settings')}
                </Link>
              </li>
              <li>
                <Link
                  to={'/settings/terminal'}
                  onClick={() => setIsMenuOpen(false)}
                  className={location.pathname === '/settings/terminal' ? 'active' : ''}
                >
                  {t('menu.terminal')}
                </Link>
              </li>
              <li>
                <Link
                  to={'/settings/cards'}
                  onClick={() => setIsMenuOpen(false)}
                  className={location.pathname === '/settings/cards' ? 'active' : ''}
                >
                  {t('menu.cards')}
                </Link>
              </li>
              <li></li>
            </>
          )}

          <li>
            <span onClick={logout}>{t('menu.logout')}</span>
          </li>

          <li className={'mt-auto'}>
            <select className={'select select-md'} value={language} onChange={changeLanguage}>
              <option value={'de'}>DE</option>
              <option value={'en'}>EN</option>
              <option value={'tr'}>TR</option>
            </select>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppLayout;
