import { apiRequest } from '@/helper/ApiHelper';
import { DateTime } from 'luxon';

export interface Shift {
  affiliate_id?: string;
  user_id: string;
  start: string;
  end: string;
  note?: string;
  project_id?: number;
}

export interface ExportParameter {
  affiliate_id: string;
  user_id: string;
  start: string;
  end: string;
  file_type: 'pdf' | 'csv' | 'excel';
  card_id?: number;
  project_id?: number;
}

export const SlotService = {
  getPeriod: async (
    start: string,
    end: string,
    affiliate_id?: string,
    user_id?: string,
    card_id?: string,
    project_id?: string,
    page_id?: number,
    page_size?: number,
  ) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/slot/period',
        data: {
          affiliate_id: affiliate_id,
          start: start,
          end: end,
          user_id: user_id,
          card_id: card_id,
          project_id: project_id,
          timezone: DateTime.local().toFormat('z'),
          page: page_id,
          page_size: page_size,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  export: async (parameter: ExportParameter) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/slot/export',
        responseType: 'blob',
        fullResponse: true,
        data: {
          ...parameter,
          timezone: DateTime.local().toFormat('z'),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
