import { t } from 'i18next';
import React from 'react';
import { BsCheckCircle, BsCheckSquare, BsSquare } from 'react-icons/bs';

export interface LicenseTypeProps {
  title: string;
  showProject: boolean;
  isLoading: boolean;
  price: number;
  toggleBackground: boolean;
  isSelected: boolean;
  setSelected: () => void;
  showCard: boolean;
}

export default function LicenseType({
  title,
  isSelected,
  setSelected,
  price,
  isLoading,
  showCard,
  showProject,
  toggleBackground,
}: LicenseTypeProps) {
  let cardClass =
    'hover:cursor-pointer border-2 mx-auto p-8 rounded-lg flex flex-col w-full bg-white';
  let innerClass = 'text-gray-700';
  let iconClass = 'text-primary';

  if (toggleBackground) {
    cardClass =
      'hover:cursor-pointer border-2 mx-auto p-8 text-white bg-gradient-to-br from-emerald-500 to-emerald-700 rounded-lg flex flex-col w-full';
    innerClass = 'text-white';
    iconClass = 'text-white';
  }

  return (
    <div
      onClick={setSelected}
      className={[cardClass, isSelected ? 'border-primary' : ''].join(' ')}
    >
      <div className="flex mb-4 border-b pb-3 items-center">
        <div className={'text-3xl text-left font-bold ' + innerClass}>{title}</div>
        <div className="font-bold p-2 bg-emerald-500 rounded-lg text-xs text-white ml-auto">
          <p>
            {isLoading ? (
              <span className={'loading loading-xs loading-spinner'}></span>
            ) : (
              <span>{price} € / mntl.</span>
            )}
          </p>
        </div>
      </div>
      <div className={innerClass}>
        <div className="flex text-lg">
          <div className={'flex-1 mb-2 text-left font-bold ' + innerClass}>
            {t('commerce.licenses.time_tracking')}
          </div>
        </div>
        <div className="flex">
          <div className="ml-2">
            <div className="flex">
              <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
              <span>{t('commerce.licenses.time_tracking')}</span>
            </div>
            <div className="flex">
              <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
              <span>{t('commerce.licenses.vacation_planner')}</span>
            </div>
            <div className="flex">
              <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
              <span>{t('commerce.licenses.illness_tracker')}</span>
            </div>
            <div className="flex">
              <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
              <span>{t('commerce.licenses.employee_access')}</span>
            </div>
          </div>
        </div>
        {showProject && (
          <>
            <div className="flex text-lg mt-4">
              <div className={'flex-1 mb-2 text-left font-bold ' + innerClass}>
                {t('commerce.licenses.project_addon')}
              </div>
            </div>
            <div className="flex">
              <div className="ml-2">
                <div className="flex">
                  <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
                  <span>{t('commerce.licenses.create_projects')}</span>
                </div>
                <div className="flex">
                  <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
                  <span>{t('commerce.licenses.project_analytics')}</span>
                </div>
                <div className="flex">
                  <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
                  <span>{t('commerce.licenses.individual_config')}</span>
                </div>
                <div className="flex">
                  <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
                  <span>{t('commerce.licenses.map_employees')}</span>
                </div>
              </div>
            </div>
          </>
        )}
        {showCard && (
          <>
            <div className="flex mt-4 text-lg">
              <div className={'flex-1 mb-2 text-left font-bold ' + innerClass}>
                {t('commerce.licenses.card_addon')}
              </div>
            </div>
            <div className="flex mb-4">
              <div className="ml-2">
                <div className="flex">
                  <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
                  <span>{t('commerce.licenses.cardreader')}</span>
                </div>
                <div className="flex">
                  <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
                  <span>{t('commerce.licenses.stamp')}</span>
                </div>
                <div className="flex">
                  <BsCheckCircle size={17} className={iconClass + ' mr-3 mt-1'} />
                  <span>{t('commerce.licenses.individual_config')}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="mt-6 xl:mt-auto items-center group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-emerald-500 duration-200 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:bg-emerald-700">
        <span className="mr-2">{t('general.select')}</span>
        {isSelected ? <BsCheckSquare size={17} /> : <BsSquare size={17} />}
      </div>
    </div>
  );
}
