import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  //const error = useRouteError();
  //console.error(error);
  const { t } = useTranslation();

  return (
    <div className="hero min-h-screen">
      <div className="hero-content">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold text-center">{t('error_page.headline')}</h1>
          <p className="py-6 text-center">{t('error_page.message')}</p>
          <div className="mockup-code">
            <pre>
              <code>Not Found</code>
            </pre>
          </div>
          <Link to={'/'}>
            <button className="btn btn-primary text-white mt-6">
              {t('error_page.back_to_app')}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
