import React, { useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const CheckoutForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>(null);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    elements?.update({ locale: i18n.language as any });
  }, [i18n.language]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const response = await stripe?.confirmSetup({
      elements: elements,
      clientSecret: clientSecret,
      confirmParams: {
        return_url:
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port !== '80' ? ':' + window.location.port : '') +
          '/onboarding/confirm',
      },
    });

    if (response.error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(response.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      {errorMessage && (
        <Alert variant={'destructive'} className="mt-5">
          <AlertCircle />
          <AlertTitle>{t('onboarding.payment.error')}</AlertTitle>
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}
      <form id="add-payment-method" onSubmit={handleSubmit} className="mt-5">
        <PaymentElement
          options={{
            layout: 'tabs',
          }}
        />

        <div className="w-full flex justify-end">
          <button
            type="submit"
            className="btn btn-primary mt-3 ml-auto "
            disabled={!stripe || !elements}
          >
            {t('onboarding.payment.add_payment')}
          </button>
        </div>
      </form>
    </>
  );
};
