import { Project } from '@/components/Tables/ProjectOverviewTable';
import { apiRequest } from '@/helper/ApiHelper';

export const ProjectService = {
  getProject: async (project_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/project/' + project_id,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  createProject: async (affiliate_id: string, title: string, user_ids: number[]) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/project',
        method: 'POST',
        data: {
          affiliate_id: affiliate_id,
          title: title,
          user_ids: user_ids,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  updateProject: async (project_id: string, title: string, user_ids: number[]) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/project/' + project_id,
        method: 'PATCH',
        data: {
          title: title,
          user_ids: user_ids,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  deleteProject: async (project_id: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/project/' + project_id,
        method: 'DELETE',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getProjects: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/project/affiliate/' + affiliate_id,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
