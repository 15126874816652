import React from 'react';
import logo from '@/assets/logo_wp.png';

export default function Health() {
  return (
    <div>
      <div className="navbar bg-base-100 shadow-sm rounded-none">
        <div className="navbar-start"></div>
        <div className="navbar-center">
          <img alt={'Workplan.Digital Logo'} src={logo} className="w-10" />
        </div>
        <div className="navbar-end"></div>
      </div>

      <main className={'flex flex-col items-center'}>
        <div className={'p-10'}>
          <p>System is healthy. Thanks for asking.</p>
        </div>
      </main>
    </div>
  );
}
