import React, { useEffect, useState } from 'react';
import { createErrorNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { LoadingComponent } from '@/components/General/LoadingComponent';
import { DropdownActions } from '@/components/General/DropdownActions';
import { BsClock } from 'react-icons/bs';
import { SlotListModal } from '@/components/Modal/SlotListModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShiftService } from '@/services/ShiftService';
import { useTranslation } from 'react-i18next';

export default function TimeOverview() {
  const { state, pathname } = useLocation();
  const { t } = useTranslation();

  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [monthOffset, setMonthOffset] = useState(0);
  const [timeRange, setTimeRange] = useState({
    start: DateTime.now().startOf('month'),
    end: DateTime.now().endOf('month').set({ millisecond: 0 }),
  });
  const [selectedUser, setSelectedUser] = useState({});
  const [isShiftModalOpen, setIsShiftModalOpen] = useState(false);
  const navigate = useNavigate();

  const setStartDate = (evt) => {
    const date = DateTime.fromFormat(evt.target.value, 'y-MM-dd');
    setTimeRange({ ...timeRange, start: date });
    queryTimeOverview(date, timeRange.end);
  };

  const setEndDate = (evt) => {
    const date = DateTime.fromFormat(evt.target.value, 'y-MM-dd');
    setTimeRange({ ...timeRange, end: date });
    queryTimeOverview(timeRange.start, date);
  };

  const queryTimeOverview = (start, end) => {
    setIsLoading(true);
    ShiftService.getOverview(
      selectedAffiliate.id,
      start.toISO({ suppressMilliseconds: true }),
      end.set({ hour: 23, minute: 59, second: 59 }).toISO({ suppressMilliseconds: true }),
    )
      .then((res: any) => {
        setUser(Object.values(res));
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openDetailsPage = (user) => {
    navigate('/time/export', {
      state: {
        user: {
          id: user?.id,
          name: user?.name,
        },
        start: timeRange.start.toISO({ suppressMilliseconds: true }),
        end: timeRange.end
          .endOf('day')
          .set({ millisecond: 0 })
          .toISO({ suppressMilliseconds: true }),
        from: { pathname },
      },
    });
    //setSelectedUser(user);
    //setIsShiftModalOpen(true);
  };

  useEffect(() => {
    let tmpStart = timeRange.start;
    let tmpEnd = timeRange.end;

    if (state !== null && state.start !== undefined && state.end !== undefined) {
      tmpStart = DateTime.fromISO(state.start);
      tmpEnd = DateTime.fromISO(state.end);

      setTimeRange({ start: tmpStart, end: tmpEnd });
    }

    queryTimeOverview(tmpStart, tmpEnd);
  }, [state]);

  return (
    <div>
      <SlotListModal
        start={timeRange.start}
        end={timeRange.end}
        reload={() => {}}
        userId={selectedUser}
        projectId={null}
        isModalOpen={isShiftModalOpen}
        setIsModalOpen={setIsShiftModalOpen}
      />
      <div
        className={
          'flex justify-center lg:justify-start items-center p-3 bg-gray-50 space-x-2 lg:space-x-4'
        }
      >
        <div className="form-control max-w-xs">
          <label className="label">
            <span className="label-text-alt">{t('time_tracking.start')}</span>
          </label>
          <input
            type={'date'}
            className={'input input-bordered max-w-xs'}
            onChange={() => setMonthOffset(monthOffset - 1)}
            onInput={setStartDate}
            value={timeRange.start.toFormat('y-MM-dd')}
          />
        </div>
        <span className={'mt-7'}>-</span>
        <div className="form-control max-w-xs">
          <label className="label">
            <span className="label-text-alt">{t('general_settings.time_tracking.end')}</span>
          </label>
          <input
            type={'date'}
            className={'input input-bordered max-w-xs'}
            onChange={() => setMonthOffset(monthOffset + 1)}
            onInput={setEndDate}
            value={timeRange.end.toFormat('y-MM-dd')}
          />
        </div>
      </div>
      {isLoading ? (
        <LoadingComponent paddingTop={true} />
      ) : (
        <table className="table table-zebra table-fixed">
          <thead>
            <tr>
              <th>{t('general.table_headers.name')}</th>
              <th>{t('general.table_headers.hours')}</th>
              <th>{t('general.table_headers.saldo')}</th>
              <th>{t('general.table_headers.holiday')}</th>
              <th>{t('general.table_headers.sick_days')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {user.map((userData: any, key: number) => {
              return (
                <tr key={key}>
                  <td>{userData.name}</td>
                  <td>
                    <div className={'flex flex-col lg:flex-row items-center'}>
                      <span className={'text-bold'}>{userData?.saldo.netto} h</span>
                      <span className={'text-gray-400 hidden lg:block mx-1'}>von</span>
                      <span className={'text-gray-400'}>{userData?.saldo.brutto} h</span>
                    </div>
                  </td>
                  <td className={'py-8 text-primary'}>
                    <div className={'flex flex-col lg:flex-row items-center'}>
                      <span
                        className={[
                          'text-bold',
                          parseFloat(userData.saldo.amount) >= 0 ? 'text-primary' : 'text-error',
                        ].join(' ')}
                      >
                        {userData.saldo.amount}
                      </span>
                      <span className={'text-gray-400 lg:ml-1'}>{userData.saldo.unit}</span>
                    </div>
                  </td>
                  <td>
                    <div className={'flex flex-col lg:flex-row items-center'}>
                      <span className={'text-bold'}>{userData?.saldo.vacation_days_taken}</span>
                      <span className={'text-gray-400 lg:ml-1'}>Tage</span>
                    </div>
                  </td>
                  <td>
                    <div className={'flex flex-col lg:flex-row items-center'}>
                      <span className={'text-bold'}>{userData?.saldo.sick_days}</span>
                      <span className={'text-gray-400 lg:ml-1'}>Tag</span>
                    </div>
                  </td>
                  <td>
                    <DropdownActions
                      actions={[
                        {
                          title: t('time_tracking.all_shifts'),
                          icon: <BsClock size={17} />,
                          action: () => openDetailsPage(userData),
                        },
                      ]}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div className={'m-4 mx-6 flex justify-end'}>
        <div className={'btn btn-primary text-white'} onClick={() => openDetailsPage(null)}>
          <BsClock size={17} /> {t('time_tracking.all_shifts')}
        </div>
      </div>
    </div>
  );
}
