import { apiRequest } from '@/helper/ApiHelper';
import { DateTime } from 'luxon';
import { ExportParameter } from './SlotService';

export interface Shift {
  affiliate_id?: string;
  user_id: string;
  start: string;
  end: string;
  note?: string;
  project_id?: string;
}

export const ShiftService = {
  createShift: async (shift: Shift) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift',
        method: 'POST',
        data: shift,
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getShift: async (shift_id: number) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift/' + shift_id,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  updateShift: async (shift_id: string, shift: Shift) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift/' + shift_id,
        method: 'PATCH',
        data: shift,
      })
        .then(resolve)
        .catch(reject);
    });
  },
  deleteShift: async (shift_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift/' + shift_id,
        method: 'DELETE',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getPeriod: async (
    start: string,
    end: string,
    affiliate_id?: string,
    user_id?: string,
    card_id?: string,
    project_id?: string,
  ) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift/period',
        data: {
          affiliate_id: affiliate_id,
          start: start,
          end: end,
          user_id: user_id,
          card_id: card_id,
          project_id: project_id,
          timezone: DateTime.local().toFormat('z'),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getCalendar: async (affiliate_id: number, start: string, end: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift/calendar',
        method: 'POST',
        data: {
          affiliate_id: affiliate_id,
          start: start,
          end: end,
          timezone: DateTime.now().toFormat('z'),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getOverview: async (affiliate_id: string, start?: string, end?: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift/overview',
        method: 'POST',
        data: {
          affiliate_id: affiliate_id,
          start: start,
          end: end,
          timezone: DateTime.now().toFormat('z'),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getModal: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift/modal/create/' + affiliate_id,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  export: async (parameter: ExportParameter) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/shift/export',
        responseType: 'blob',
        fullResponse: true,
        data: {
          ...parameter,
          timezone: DateTime.local().toFormat('z'),
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
