import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import localEN from '@/i18n/locales/en.json';
import localDE from '@/i18n/locales/de.json';
import localTR from '@/i18n/locales/tr.json';

i18next
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    fallbackLng: 'de',
    resources: {
      de: {
        translation: localDE,
      },
      en: {
        translation: localEN,
      },
      tr: {
        translation: localTR,
      },
    },
  })
  .then(() => {})
  .catch(() => {});

export default i18next;
