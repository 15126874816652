import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    refresh_token: null,
  },
  reducers: {
    updateToken(state, action) {
      state.token = action.payload;
    },
    loginUser(state, action) {
      state.token = action.payload.token;
      state.refresh_token = action.payload.refreshToken;
    },
    logoutUser(state) {
      state.token = null;
      state.refresh_token = null;
    },
  },
});

export const { updateToken, loginUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
