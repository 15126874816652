import { UserConfigurationDynamic } from '@/types/User/UserConfiguration';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { ApiMessageResponse } from '@/types/Api/ApiTypes';
import { AxiosError } from 'axios';
import { AffiliateService } from '@/services/AffiliateService';
import { createNotification } from '@/helper/ApiHelper';
import { UserStaticConfigurationProps } from '@/components/Form/User/EditUserConfigurationStaticForm';

export interface UserConfigurationMutationProps {
  affiliateId: string;
  userId: string;
  data: UserConfigurationDynamic[];
}

export const useUserConfiguration = (affiliateId: string, userId: string) => {
  return useQuery<unknown, Error, UserConfigurationDynamic>({
    queryKey: ['user-config', affiliateId, userId],
    queryFn: () => AffiliateService.getCurrentUserConfiguration(affiliateId, userId),
  });
};

export const mutateDynamicUserConfiguration = (
  queryClient: QueryClient,
  t: (arg0: string) => string,
) => {
  return useMutation<ApiMessageResponse, AxiosError<ApiMessageResponse>, any>({
    mutationFn: async ({ userId, affiliateId, data }: UserConfigurationMutationProps) => {
      await AffiliateService.updateUserConfigurations(affiliateId, userId, data);
      return { userId, affiliateId, test: 'test2' };
    },
    onSuccess: (context, variable: UserConfigurationMutationProps) => {
      const mutationKeys = Object.values(variable.data)
        .filter((config) => config.id > 0)
        .map((config) => ['user-config', config.id]);

      mutationKeys.map((key) => queryClient.invalidateQueries({ queryKey: key }));
      queryClient.invalidateQueries({
        queryKey: ['profile-page', context.affiliateId, context.userId],
      });

      createNotification(
        t('api.error.USER_CONFIGURATION_UPDATED.title'),
        t('api.error.USER_CONFIGURATION_UPDATED.body'),
        'success',
      );
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export const mutateStaticUserConfig = (queryClient: QueryClient, t: (arg0: string) => string) => {
  return useMutation<ApiMessageResponse, AxiosError<ApiMessageResponse>, any>({
    mutationFn: async ({ userId, affiliateId, data }: UserStaticConfigurationProps) => {
      await AffiliateService.updateStaticUserConfiguration(affiliateId, userId, data);
      return { userId, affiliateId };
    },
    onSuccess: (context: any, variable: UserStaticConfigurationProps) => {
      const mutationKeys = Object.values(variable.data)
        .filter((config: any) => config.id > 0)
        .map((config: any) => ['user-config', config.id]);

      mutationKeys.map((key) => queryClient.invalidateQueries({ queryKey: key }));
      queryClient.invalidateQueries({
        queryKey: ['profile-page', context.affiliateId, context.userId],
      });

      createNotification(
        t('api.error.USER_CONFIGURATION_UPDATED.title'),
        t('api.error.USER_CONFIGURATION_UPDATED.body'),
        'success',
      );
    },
  });
};
