import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { apiRequest, createErrorNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { ProjectService } from '@/services/ProjectService';
import { DeleteProjectModal } from '@/components/Modal/DeleteProjectModal';

export const EditProjectModal = ({
  isModalOpen,
  setIsModalOpen,
  users,
  project,
  reloadProjects,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editProject, setEditProject] = useState({
    title: '',
    users: {},
  });

  useEffect(() => {
    let tmpUsers = {};
    Object.values(project.users)?.forEach((user: any) => {
      tmpUsers[user.id] = parseInt(user.id);
    });

    setEditProject({
      title: project.title,
      users: tmpUsers,
    });
  }, [project]);

  const setTitle = (evt) => {
    setEditProject({ ...editProject, title: evt.target.value });
  };

  const toggleUser = (id: string) => {
    let isActive = editProject.users[id] ?? false;
    let tmpUsers = editProject.users;
    if (isActive) {
      delete tmpUsers[id];
    } else {
      tmpUsers[id] = parseInt(id);
    }

    setEditProject({ ...editProject, users: { ...tmpUsers } });
  };

  const onAfterDelete = async () => {
    await reloadProjects();
    setIsModalOpen(false);
  };

  const safeProject = () => {
    setIsSaving(true);
    ProjectService.updateProject(project.id, editProject.title, Object.values(editProject.users))
      .then(() => {
        reloadProjects();
        setIsModalOpen(false);
      })
      .catch(createErrorNotification)
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <Modal
        modalName="edit_project"
        headline={t('projects.edit_project')}
        isOpen={isModalOpen}
        isLoading={isSaving}
        disableSafe={editProject.title === '' || Object.values(editProject.users).length === 0}
        onSafe={safeProject}
        onClose={() => {
          setIsModalOpen(false);
        }}
        showDeleteButton={true}
        onDelete={() => setIsDeleteModalOpen(true)}
      >
        <div className="space-y-1">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">{t('projects.project_title')}</span>
            </label>
            <input
              type="text"
              name={'title'}
              onChange={setTitle}
              value={editProject.title}
              placeholder=""
              className="input input-bordered w-full"
            />
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">{t('projects.member')}</span>
            </label>
            <div>
              {users.map((user: any, id) => {
                return (
                  <div key={id} className="form-control">
                    <label className="label cursor-pointer justify-start">
                      <input
                        type="checkbox"
                        className="checkbox mr-4"
                        checked={!!editProject.users[user.id]}
                        onChange={() => toggleUser(user.id)}
                      />
                      <span className="label-text">{user.fullname}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
      <DeleteProjectModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        projectId={project.id}
        projectName={project.title}
        reload={onAfterDelete}
      />
    </>
  );
};
