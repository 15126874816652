import { apiRequest } from '@/helper/ApiHelper';

interface CheckoutParameter {
  affiliate: {
    state: string;
    title: string;
  };
  number_employees: number;
  license_type:
    | 'workplan_team_license'
    | 'workplan_business_license'
    | 'workplan_enterprise_license';
}

export const PaymentService = {
  getPaymentLink: async (parameter: CheckoutParameter) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment',
        method: 'POST',
        data: parameter,
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getSetupIntent: async () => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/intent',
        method: 'POST',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  getPrices: async () => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/prices',
        method: 'GET',
      })
        .then((res: any) => {
          let formattedPrices = {};
          Object.keys(res).forEach((priceId) => {
            formattedPrices[priceId] = {};
            res[priceId].tiers?.forEach((tier, key) => {
              formattedPrices[priceId][key] = {
                flat_amount: tier.flat_amount
              };
            });
          });

          resolve(formattedPrices);
        })
        .catch(reject);
    });
  },
  getCurrentLicense: async (affiliateId) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/subscription/' + affiliateId,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  redirectToCheckout: (affiliateId, licenseType, numberEmployees) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment',
        method: 'POST',
        responseType: 'json',
        data: {
          affiliate_id: affiliateId,
          number_subscriptions: parseInt(numberEmployees),
          license_type: licenseType,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
  redirectToPortal: () => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/subscription/portal',
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  updateSubscription: (affiliateId, licenseType, numberEmployees, instantUpgrade = false) => {
    return new Promise((resolve, reject) => {
      apiRequest({
        url: '/api/payment/subscription/' + affiliateId,
        method: 'PATCH',
        responseType: 'json',
        data: {
          number_employees: parseInt(numberEmployees),
          license_type: licenseType,
          instant_upgrade: instantUpgrade,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
