import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { BsTrash, BsPlusLg, BsGear, BsQuestionCircle } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingComponent } from '@/components/General/LoadingComponent';
import { AffiliateSettingsService } from '@/services/AffiliateSettingsService';
import Navbar from '@/components/Menu/Navbar';
import { useTranslation } from 'react-i18next';

export default function GeneralSettingsPage() {
  const { t } = useTranslation();
  const [affiliate, setAffiliate] = useState({
    title: '',
    state: '',
    time_tracking_options: [],
    edit_shift_time_after_creation: 0,
    time_management_config: {
      round_mode_start: '',
      round_mode_end: '',
      round_minute_step_start: 0,
      round_minute_step_end: 0,
    },
    opening_hours: [],
  }) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);

  const [newTimeSlot, setNewTimeSlot] = useState({
    weekday: 'monday',
    start: '',
    end: '',
  });

  const deleteOpeningHour = (key) => {
    let tmpHours = affiliate.opening_hours;
    tmpHours = tmpHours.filter((obj) => {
      return obj.id !== key;
    });
    setAffiliate({ ...affiliate, opening_hours: tmpHours });
  };

  const updateAffiliate = (evt) => {
    if (evt.target.name.includes('time_management_config')) {
      setAffiliate({
        ...affiliate,
        time_management_config: {
          ...affiliate.time_management_config,
          [evt.target.name.replace('time_management_config.', '')]: evt.target.value,
        },
      });
    } else if (
      ['time_tracking_card', 'time_tracking_stamp', 'time_tracking_calendar'].includes(
        evt.target.name,
      )
    ) {
      let currentValues = affiliate.time_tracking_options;
      if (evt.target.checked) {
        currentValues.push(evt.target.name);
      } else {
        currentValues = currentValues.filter((item) => item !== evt.target.name);
      }

      console.log(currentValues);

      setAffiliate({ ...affiliate, time_tracking_options: currentValues });
    } else {
      setAffiliate({ ...affiliate, [evt.target.name]: evt.target.value });
    }
  };

  const setTimeslot = (evt) => {
    setNewTimeSlot({ ...newTimeSlot, [evt.target.name]: evt.target.value });
  };

  const addOpeningHour = () => {
    if (newTimeSlot.start === '' || newTimeSlot.end === '') {
      createNotification(
        t('general_settings.notifications.new_openingtime_error_header'),
        t('general_settings.notifications.new_openingtime_error_message'),
        'error',
      );
      return;
    }

    const numberDay = {
      monday: 10000,
      tuesday: 20000,
      wednesday: 30000,
      thursday: 40000,
      friday: 50000,
      saturday: 60000,
      sunday: 70000,
    };

    let hours = affiliate.opening_hours;
    hours.push({
      id: Math.round(Math.random() * 10000),
      sort: numberDay[newTimeSlot.weekday] + parseInt(newTimeSlot.start.replace(':', '')),
      weekday: newTimeSlot.weekday,
      start: newTimeSlot.start,
      end: newTimeSlot.end,
    });

    hours = hours.sort((a, b) => {
      return a.sort - b.sort;
    });

    setAffiliate({ ...affiliate, opening_hours: hours });
  };

  const updateSettings = () => {
    setIsSaving(true);
    let hours = Object.values(affiliate.opening_hours).map((timeslot: any) => {
      return {
        weekday: timeslot.weekday,
        start: timeslot.start + ':00',
        end: timeslot.end + ':00',
      };
    });

    let tmpAffiliate = { ...affiliate };
    tmpAffiliate.opening_hours = hours;
    tmpAffiliate.time_management_config.round_minute_step_start = parseInt(
      tmpAffiliate.time_management_config.round_minute_step_start,
    );
    tmpAffiliate.time_management_config.round_minute_step_end = parseInt(
      tmpAffiliate.time_management_config.round_minute_step_end,
    );

    tmpAffiliate.edit_shift_time_after_creation = parseInt(
      tmpAffiliate.edit_shift_time_after_creation,
    );

    AffiliateSettingsService.updateSettings(selectedAffiliate.id, tmpAffiliate)
      .then(() => {
        createNotification(
          t('general_settings.notifications.save_settings_header'),
          t('general_settings.notifications.save_settings_message'),
          'success',
        );
      })
      .catch((err: any) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const loadSettings = () => {
    setIsLoading(true);
    AffiliateSettingsService.getSettings(selectedAffiliate.id)
      .then((res: any) => {
        setAffiliate(res);
      })
      .catch((err: any) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadSettings();
  }, []);

  if (isLoading)
    return (
      <div className={'p-6'}>
        <LoadingComponent paddingTop={true} />
      </div>
    );

  return (
    <>
      <Navbar
        end={
          <div className="p-4 w-full flex flex-row justify-end" onClick={updateSettings}>
            <div className={'btn btn-primary text-white ' + (isSaving ? 'btn-disabled' : '')}>
              {isSaving ? <span className={'loading loading-spinner'} /> : <BsGear size={17} />}{' '}
              {t('general.save')}
            </div>
          </div>
        }
      />
      <div className={''}>
        <div className="lg:flex lg:space-x-4">
          <div className="lg:w-full p-6">
            <div className="prose">
              <h3>{t('general_settings.general.title')}</h3>
            </div>
            <div className="space-y-4">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t('general_settings.general.company_name')}</span>
                </label>
                <input
                  type="text"
                  placeholder=""
                  name={'title'}
                  onChange={updateAffiliate}
                  value={affiliate.title}
                  className="input input-bordered w-full"
                />
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t('general_settings.general.company_state')}</span>
                </label>
                <select
                  name={'state'}
                  onChange={updateAffiliate}
                  value={affiliate.state}
                  className="select select-bordered w-full"
                >
                  <option disabled>{t('general.please_select')}</option>
                  <option value="None">{t('general.no_holidays')}</option>
                  <option value="DE-BW">{t('general_settings.country.germany.state.de-bw')}</option>
                  <option value="DE-BY">{t('general_settings.country.germany.state.de-by')}</option>
                  <option value="DE-BE">{t('general_settings.country.germany.state.de-be')}</option>
                  <option value="DE-BB">{t('general_settings.country.germany.state.de-bb')}</option>
                  <option value="DE-HB">{t('general_settings.country.germany.state.de-hb')}</option>
                  <option value="DE-HH">{t('general_settings.country.germany.state.de-hh')}</option>
                  <option value="DE-HE">{t('general_settings.country.germany.state.de-he')}</option>
                  <option value="DE-NI">{t('general_settings.country.germany.state.de-ni')}</option>
                  <option value="DE-MV">{t('general_settings.country.germany.state.de-mv')}</option>
                  <option value="DE-NW">{t('general_settings.country.germany.state.de-nw')}</option>
                  <option value="DE-RP">{t('general_settings.country.germany.state.de-rp')}</option>
                  <option value="DE-SL">{t('general_settings.country.germany.state.de-sl')}</option>
                  <option value="DE-SN">{t('general_settings.country.germany.state.de-sn')}</option>
                  <option value="DE-ST">{t('general_settings.country.germany.state.de-st')}</option>
                  <option value="DE-SH">{t('general_settings.country.germany.state.de-sh')}</option>
                  <option value="DE-TH">{t('general_settings.country.germany.state.de-th')}</option>
                </select>
              </div>
            </div>
          </div>
          <div className="divider lg:hidden"></div>
          <div className="lg:w-full p-6 pt-2 lg:pt-6">
            <div className="prose inline-flex gap-x-2 items-center">
              <h3>{t('general_settings.time_tracking.card_terminal')}</h3>{' '}
              <a target="_blank" href="https://docs.workplan.digital/einstellungen/karten-terminal">
                <BsQuestionCircle size={16} className="mb-3" />
              </a>
            </div>

            <div className="space-y-4">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t('general_settings.time_tracking.start')}</span>
                </label>
                <select
                  name={'time_management_config.round_mode_start'}
                  onChange={updateAffiliate}
                  value={affiliate.time_management_config.round_mode_start}
                  className="select select-bordered w-full"
                >
                  <option disabled>{t('general.please_select')}</option>
                  <option value={'round_up'}>
                    {t('general_settings.time_tracking.round_positiv')}
                  </option>
                  <option value={'round_off'}>
                    {t('general_settings.time_tracking.round_negativ')}
                  </option>
                  <option value={'opening_times'}>
                    {t('general_settings.time_tracking.opening_hours')}
                  </option>
                </select>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t('general_settings.time_tracking.round_steps_in_minutes')}
                  </span>
                </label>
                <input
                  name={'time_management_config.round_minute_step_start'}
                  type="number"
                  min="0"
                  max="60"
                  placeholder="0"
                  onChange={updateAffiliate}
                  value={affiliate.time_management_config.round_minute_step_start}
                  className="input input-bordered w-full"
                />
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">{t('general_settings.time_tracking.end')}</span>
                </label>
                <select
                  name={'time_management_config.round_mode_end'}
                  onChange={updateAffiliate}
                  value={affiliate.time_management_config.round_mode_end}
                  className="select select-bordered w-full"
                >
                  <option disabled>{t('general.please_select')}</option>
                  <option value={'round_up'}>
                    {t('general_settings.time_tracking.round_positiv')}
                  </option>
                  <option value={'round_off'}>
                    {t('general_settings.time_tracking.round_negativ')}
                  </option>
                  <option value={'opening_times'}>
                    {t('general_settings.time_tracking.opening_hours')}
                  </option>
                </select>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t('general_settings.time_tracking.round_steps_in_minutes')}
                  </span>
                </label>
                <input
                  name={'time_management_config.round_minute_step_end'}
                  type="number"
                  min="0"
                  max="60"
                  placeholder="0"
                  onChange={updateAffiliate}
                  value={affiliate.time_management_config.round_minute_step_end}
                  className="input input-bordered w-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="lg:flex lg:space-x-4">
          <div className="lg:w-full p-6">
            <div className="prose">
              <h3>{t('general_settings.time_tracking.title')}</h3>
            </div>
            <div className="space-y-4">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t('general_settings.time_tracking.edit_after_creation')}
                  </span>
                </label>
                <select
                  name={'edit_shift_time_after_creation'}
                  onChange={updateAffiliate}
                  value={affiliate.edit_shift_time_after_creation}
                  className="select select-bordered w-full"
                >
                  <option disabled>{t('general.please_select')}</option>
                  <option value={'null'}>{t('general_settings.time_tracking.no_limit')}</option>
                  <option value={0}>{t('general_settings.time_tracking.duration.0')}</option>
                  <option value={5}>{t('general_settings.time_tracking.duration.5')}</option>
                  <option value={10}>{t('general_settings.time_tracking.duration.10')}</option>
                  <option value={15}>{t('general_settings.time_tracking.duration.15')}</option>
                  <option value={30}>{t('general_settings.time_tracking.duration.30')}</option>
                  <option value={60}>{t('general_settings.time_tracking.duration.60')}</option>
                  <option value={120}>{t('general_settings.time_tracking.duration.120')}</option>
                  <option value={240}>{t('general_settings.time_tracking.duration.240')}</option>
                  <option value={480}>{t('general_settings.time_tracking.duration.480')}</option>
                  <option value={1440}>{t('general_settings.time_tracking.duration.1440')}</option>
                </select>
              </div>
            </div>
          </div>
          <div className="divider lg:hidden"></div>
          <div className="lg:w-full p-6 pt-2 lg:pt-6">
            <div className="lg:flex lg:space-x-4">
              <div className="form-control w-full">
                <div className="prose">
                  <h3>{t('general_settings.time_tracking.allowed_tracking_methods')}</h3>
                </div>

                <label className="label cursor-pointer">
                  <span className="label-text">
                    {t('general_settings.time_tracking.option_calendar')}
                  </span>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={affiliate.time_tracking_options.includes('time_tracking_calendar')}
                    name={'time_tracking_calendar'}
                    onChange={updateAffiliate}
                  />
                </label>

                <label className="label cursor-pointer">
                  <span className="label-text">
                    {t('general_settings.time_tracking.option_stamp')}{' '}
                  </span>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={affiliate.time_tracking_options.includes('time_tracking_stamp')}
                    name={'time_tracking_stamp'}
                    onChange={updateAffiliate}
                  />
                </label>

                <label className="label cursor-pointer">
                  <span className="label-text">
                    {t('general_settings.time_tracking.option_card')}
                  </span>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={affiliate.time_tracking_options.includes('time_tracking_card')}
                    name={'time_tracking_card'}
                    onChange={updateAffiliate}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div className={'p-6 pt-2'}>
          <div className="prose mb-1 inline-flex gap-x-2 items-center">
            <h3>{t('general_settings.opening_hours.title')}</h3>{' '}
            <a target="_blank" href="https://docs.workplan.digital/einstellungen/offnungszeiten">
              <BsQuestionCircle size={16} className="mb-3" />
            </a>
          </div>

          <div className="space-y-4">
            <div className="join join-vertical lg:join-horizontal w-full">
              <select
                name={'weekday'}
                value={newTimeSlot.weekday}
                onChange={setTimeslot}
                className="select select-bordered join-item w-full"
              >
                <option value="monday">{t('general.day.monday')}</option>
                <option value="tuesday">{t('general.day.tuesday')}</option>
                <option value="wednesday">{t('general.day.wednesday')}</option>
                <option value="thursday">{t('general.day.thursday')}</option>
                <option value="friday">{t('general.day.friday')}</option>
                <option value="saturday">{t('general.day.saturday')}</option>
                <option value="sunday">{t('general.day.sunday')}</option>
              </select>
              <div className={'lg:w-2/5'}>
                <div>
                  <input
                    name={'start'}
                    value={newTimeSlot.start}
                    onChange={setTimeslot}
                    type="time"
                    className="input input-bordered join-item w-full"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className={'lg:w-2/5'}>
                <div>
                  <input
                    name={'end'}
                    value={newTimeSlot.end}
                    onChange={setTimeslot}
                    type="time"
                    className="input input-bordered join-item w-full"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div
                className="indicator join-item btn btn-primary text-white w-full lg:w-auto"
                onClick={addOpeningHour}
              >
                <BsPlusLg className="text-secondary-content mx-4" />
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="table table-zebra lg:table-fixed">
                <thead>
                  <tr>
                    <th>{t('general.day.day')}</th>
                    <th>{t('general_settings.opening_hours.start')}</th>
                    <th>{t('general_settings.opening_hours.end')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {affiliate.opening_hours?.map((timeslot, key) => {
                    return (
                      <tr key={key}>
                        <td>{t('general.day.' + timeslot.weekday)}</td>
                        <td>{timeslot.start}</td>
                        <td>{timeslot.end}</td>
                        <td>
                          <BsTrash
                            size={17}
                            className="text-error cursor-pointer"
                            onClick={() => deleteOpeningHour(timeslot.id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
