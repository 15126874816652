import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { UserConfigurationDynamic, UserConfigurationStatic } from '@/types/User/UserConfiguration';
import { useTranslation } from 'react-i18next';
import { useUserProfile } from '@/integrations/useUserProfile';
import EditUserConfigurationDynamicForm from '@/components/Form/User/EditUserConfigurationDynamicForm';
import { EditUserConfigurationStaticForm } from '@/components/Form/User/EditUserConfigurationStaticForm';
import Navbar from '@/components/Menu/Navbar';
import { BsArrowLeft } from 'react-icons/bs';

export default function ProfilPage() {
  const { userId } = useParams<{ userId: string }>();
  const { t } = useTranslation();
  const selectedAffiliateId = useSelector((state: any) => state.affiliate.selectedAffiliate.id);
  const [counter, forceRerender] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  const { data, isError, error, refetch: reload } = useUserProfile(selectedAffiliateId, userId!);

  if (isError) {
    return (
      <div className="p-6">
        <div className="alert alert-error">Error: {error.message}</div>
      </div>
    );
  }

  const refetch = async () => {
    await reload();
    forceRerender();
  };

  const goBack = () => {
    navigate('/affiliate/employees');
  };

  return (
    <div className="">
      <Navbar
        start={
          <div onClick={goBack} className="btn lg:ml-6 mt-3 lg:mt-0">
            <BsArrowLeft className="" />

            {t('general.back')}
          </div>
        }
      />

      <div className="prose px-6 mt-6">
        <h3>{t('general_settings.general.title')}</h3>
      </div>

      <EditUserConfigurationStaticForm
        key={counter}
        userId={userId}
        firstName={data?.first_name as string}
        lastName={data?.last_name as string}
        email={data?.email as string}
        config={data?.config.static as UserConfigurationStatic}
      />

      <EditUserConfigurationDynamicForm
        configs={data?.config.dynamic as UserConfigurationDynamic[]}
        userId={userId}
        refetch={refetch}
      />
    </div>
  );
}
