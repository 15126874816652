import React, { useState } from 'react';
import Modal from '@/components/General/Modal';
import { apiRequest, createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { t } from 'i18next';
import { AffiliateService } from '@/services/AffiliateService';
import { useSelector } from 'react-redux';

export const DeleteEmployeeInvitation = ({
  isModalOpen,
  setIsModalOpen,
  inviteId,
  userName,
  reload,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);

  const deleteUserInvitation = () => {
    setIsSaving(true);
    AffiliateService.deleteInvite(selectedAffiliate.id, parseInt(inviteId))
      .then(async () => {
        createNotification(
          'Einladung gelöscht',
          'Die Einladung wurde erfolgreich gelöscht.',
          'success',
        );
        await reload();
        setIsModalOpen(false);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Modal
      modalName="delete_employee_invite"
      headline={t('employee_page.modals.delete_invitation_header')}
      isOpen={isModalOpen}
      isLoading={isSaving}
      primaryButtonText={'delete'}
      primaryButtonClass={'btn-error'}
      onSafe={deleteUserInvitation}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="space-y-1">
        <p className="w-full">
          {t('employee_page.modals.delete_invitation_message', {
            userName: userName,
          })}
        </p>
      </div>
    </Modal>
  );
};
