import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStep } from '@/global/onboardingSlice';
import { useTranslation } from 'react-i18next';
import { createErrorNotification, createNotification, refreshToken } from '@/helper/ApiHelper';
import { AffiliateService } from '@/services/AffiliateService';
import { OnboardingLayout } from '@/components/Onboarding/OnboardingLayout';

export default function StepOne() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [token, setToken] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [isIncorrect, setIsIncorrect] = useState(false);

  useEffect(() => {
    dispatch(setStep(1));
  }, []);

  const previousStep = () => {
    navigate('/onboarding/get-started');
  };

  const joinAffiliate = () => {
    setIsConfirming(true);
    setIsIncorrect(false);

    setTimeout(
      () =>
        AffiliateService.join(token)
          .then(async () => {
            await refreshToken();
            createNotification(
              t('onboarding.join_company.joined_successfully.title'),
              t('onboarding.join_company.joined_successfully.message'),
              'success',
            );
            navigate('/');
          })
          .catch((err) => {
            createErrorNotification(err);
            setIsIncorrect(true);
          })
          .finally(() => {
            setIsConfirming(false);
          }),
      2000,
    );
  };

  return (
    <OnboardingLayout>
      <div>
        <div>
          <h2 className="text-2xl font-bold text-primary">{t('onboarding.join_company.title')}</h2>
          <p className={'my-6'}>
            {t('onboarding.join_company.subtitle')}
            <br />
            {t('onboarding.join_company.subtitle_hint')}
          </p>

          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">{t('onboarding.join_company.enter_invite_code')}</span>
            </label>
            <input
              type="text"
              name={'token'}
              value={token}
              onChange={(e) => setToken(e.target.value)}
              placeholder=""
              className="input input-bordered w-full"
            />
          </div>

          <div className={'flex flex-row justify-end mt-4'}>
            <div
              className={[
                'btn btn-primary',
                isConfirming || token.length === 0 ? 'btn-disabled' : '',
              ].join(' ')}
              onClick={joinAffiliate}
            >
              {isConfirming && <span className={'loading loading-spinner'} />}{' '}
              {t('onboarding.join_company.confirm_invitation')}
            </div>
          </div>
        </div>

        <div className="join grid grid-cols-2 mt-16 w-full sm:w-96 md:mx-auto px-6">
          <button className="join-item btn btn-outline" onClick={() => previousStep()}>
            {t('general.back')}
          </button>
          <button className="join-item btn btn-outline opacity-30">{t('general.further')}</button>
        </div>
      </div>
    </OnboardingLayout>
  );
}
