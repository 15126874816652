import React from 'react';
import { t } from 'i18next';
import logo from '@/assets/logo_wp.png';
import video from '@/assets/0010_workplan_eintragen.webm';
import { useTranslation } from 'react-i18next';

export const AuthLayout = ({ children }: any) => {
  const { i18n } = useTranslation();
  const language = i18n.language || window.localStorage.i18nextLng;

  const changeLanguage = (evt) => {
    i18n.changeLanguage(evt.target.value);
  };

  return (
    <>
      <div className="w-full lg:flex h-full mx-auto">
        <div className="lg:w-[55%] flex flex-col">
          <div className="w-[85%] mx-auto py-3 flex justify-between items-center">
            <img src={logo} width={'50px'} />
            <select
              className={'select select-ghost select-sm mt-3'}
              value={language}
              onChange={changeLanguage}
            >
              <option value={'de'}>DE</option>
              <option value={'en'}>EN</option>
              <option value={'tr'}>TR</option>
            </select>
          </div>
          <div className="mx-auto flex items-center w-full h-screen max-h-[80vh]">{children}</div>
        </div>
        <div className="hidden lg:flex w-[45%] items-center justify-center h-screen from-secondary bg-gradient-to-t to-primary">
          <div className="mx-auto w-[90%] text-center">
            <div className="flex flex-col space-y-4 text-left font-bold tracking-tight text-white text-5xl ml-4">
              <h2>{t('workplan.simple')}</h2>
              <h2>{t('workplan.time_tracking')}</h2>
              <h2>{t('workplan.with_workplan')}</h2>
            </div>
            <video className="mx-auto my-4" width={'80%'} autoPlay muted controls={false} loop>
              <source src={video} type="video/webm" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};
