import { CheckIcon } from '@radix-ui/react-icons';

import { cn } from '@/helper';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import React from 'react';

type OnboardingPackageCardProps = React.ComponentProps<typeof Card> & {
  title: string;
  subtitle: string;
  featureList: string[];
  selected: boolean | undefined;
};

export function OnboardingPackageCard({
  className,
  title,
  subtitle,
  featureList,
  selected,
  ...props
}: OnboardingPackageCardProps) {
  const stateClass =
    selected === true
      ? '' // Grüner Hintergrund für ausgewählt
      : selected === false
        ? 'opacity-50' // Opazität für nicht ausgewählt
        : ''; // Keine zusätzliche Klasse für `undefined`

  return (
    <Card
      className={cn(
        'w-full mb-4 cursor-pointer hover:shadow-lg transition-shadow ease-in-out',
        stateClass,
        className,
      )}
      {...props}
    >
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{subtitle}</CardDescription>
      </CardHeader>
      <CardContent className="pb-2">
        <div>
          {featureList.map((feature, index) => (
            <div
              key={index}
              className="mb-3 grid grid-cols-[25px_1fr] items-start last:mb-0 last:pb-0"
            >
              <CheckIcon className="flex text-primary" />
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">{feature}</p>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
      <CardFooter></CardFooter>
    </Card>
  );
}
