import React, { useEffect, useState } from 'react';
import { UserConfigurationStatic } from '@/types/User/UserConfiguration';
import { DateTime } from 'luxon';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { BsExclamationCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { mutateStaticUserConfig } from '@/integrations/useUserConfiguration';
import { exists } from 'i18next';

export interface EditUserConfigurationStaticFormProps {
  userId: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
  config: UserConfigurationStatic;
}

export interface UserStaticConfigurationProps {
  userId: string;
  affiliateId: string;
  data: UserConfigurationStatic;
}

export const EditUserConfigurationStaticForm: React.FC<EditUserConfigurationStaticFormProps> = ({
  userId,
  firstName,
  lastName,
  email,
  config,
}) => {
  const queryClient = useQueryClient();
  const affiliateId = useSelector((state: any) => state.affiliate.selectedAffiliate.id);
  const [isAdmin, setIsAdmin] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (config?.is_admin !== undefined) {
      setIsAdmin(config?.is_admin);
    }
  }, [config]);

  const {
    mutate: updateStaticConfigMutation,
    data,
    error,
    isError,
  } = mutateStaticUserConfig(queryClient, t);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const data = Object.fromEntries(new FormData(form));

    updateStaticConfigMutation({ userId, affiliateId, data });
  };

  let errorMessage = t('api.error.INVALID_PARAMETER.body');
  if (exists('api.error.' + error?.response?.data.message + '.body')) {
    errorMessage = t('api.error.' + error?.response?.data.message + '.body');
  }

  return (
    <div className="px-6">
      {isError && (
        <div role="alert" className="alert alert-error m-6 w-fit">
          <BsExclamationCircle size={20} className="text-base-500" />
          <span>{errorMessage}</span>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-3 mt-6 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              {t('general.firstname')}
            </label>
            <input
              disabled={true}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              defaultValue={firstName}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              {t('general.lastname')}
            </label>
            <input
              disabled={true}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              defaultValue={lastName}
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              {t('general.email')}
            </label>
            <input
              disabled={true}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              defaultValue={email}
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mt-6 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              {t('profile_page.employee_start_date')}
            </label>
            <input
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="date"
              name="start_date"
              defaultValue={
                config?.start_date ? DateTime.fromISO(config.start_date).toFormat('yyyy-LL-dd') : ''
              }
              placeholder="Jane"
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              {t('profile_page.employee_end_date')}
            </label>
            <input
              className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="date"
              name="end_date"
              defaultValue={
                config?.end_date ? DateTime.fromISO(config.end_date).toFormat('yyyy-LL-dd') : ''
              }
              placeholder=""
            />
          </div>

          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              {t('profile_page.is_admin')}
            </label>
            <input
              name="is_admin"
              className="checkbox checkbox-primary"
              id="grid-admin"
              type="checkbox"
              checked={isAdmin}
              onChange={() => setIsAdmin(!isAdmin)}
            />
          </div>

          <div className="w-full">
            <input type="submit" className="btn btn-primary m-3" value={t('general.save')} />
          </div>
        </div>
      </form>
    </div>
  );
};
