import React, { useState } from 'react';
import Modal from '@/components/General/Modal';
import { apiRequest, createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { AffiliateService } from '@/services/AffiliateService';

export const DeleteEmployeeModal = ({ isModalOpen, setIsModalOpen, userId, userName, reload }) => {
  const [isSaving, setIsSaving] = useState(false);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);

  const deleteUser = () => {
    setIsSaving(true);
    AffiliateService.deleteUserFromAffiliate(selectedAffiliate.id, userId)
      .then(async () => {
        createNotification(
          t('employee_page.notifications.delete_employee_header'),
          t('employee_page.notifications.delete_employee_message'),
          'success',
        );
        await reload();
        setIsModalOpen(false);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Modal
      modalName="edit_employee"
      headline={t('employee_page.modals.delete_employee_header')}
      isOpen={isModalOpen}
      isLoading={isSaving}
      primaryButtonText={'delete'}
      primaryButtonClass={'btn-error'}
      onSafe={deleteUser}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="space-y-1">
        <p className="w-full">
          {t('employee_page.modals.delete_employee_message', {
            userName: userName,
          })}
        </p>
      </div>
    </Modal>
  );
};
