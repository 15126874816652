import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { BsCheckCircle, BsClock, BsShop } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingComponent } from '@/components/General/LoadingComponent';
import { DropdownActions } from '@/components/General/DropdownActions';
import { SlotListModal } from '@/components/Modal/SlotListModal';
import UpgradeSubscriptionBanner from '@/components/Commerce/UpgradeSubscriptionBanner';
import { CardService } from '@/services/CardService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserSelect = ({ id, users, onChange, selectedUser }) => {
  let selectedUserId = selectedUser ?? '';
  const { t } = useTranslation();

  return (
    <select
      className={'select select-bordered w-full max-w-xs'}
      name={id}
      key={id}
      value={selectedUserId}
      onChange={(evt) => onChange(evt.target.value, id)}
    >
      <option value={''} disabled={true}>
        {t('general.not_assigned')}
      </option>
      {users.map((user: any, key) => {
        let userId = user.id ?? '';
        let userName = user.name ?? '';

        return (
          <option key={key} value={userId}>
            {userName}
          </option>
        );
      })}
    </select>
  );
};

export default function CardSettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const [cards, setCards] = useState([]) as any;
  const [users, setUsers] = useState([]) as any;
  const [isLoading, setIsLoading] = useState(false);
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [isShiftListModalOpen, setIsShiftListModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(null);
  const [hasLicense, setHasLicense] = useState(true);

  const assignCard = (userId, cardKey) => {
    let tmpCards = [...cards];
    tmpCards[cardKey].user = userId;
    setCards(tmpCards);
  };

  const saveCard = (uuid) => {
    setIsSaving(uuid);
    let card = cards.filter((card) => {
      return card.uuid === uuid;
    })[0];

    CardService.assignCard(uuid, card.user)
      .then(() => {
        createNotification(
          t('cards.notifications.card_property_saved_header'),
          t('cards.notifications.card_property_saved_message', {
            uuid: uuid,
          }),
          'success',
        );
      })
      .catch((err: any) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(null);
      });
  };

  const openShiftModal = (card) => {
    navigate('/time/export', {
      state: {
        user: {
          id: card.user,
          name: card.name,
        },
        start: null,
        end: null,
        projectId: null,
        cardId: card.id,
        from: location,
      },
    });
  };

  const loadCards = () => {
    setIsLoadingCards(true);
    CardService.getCards(selectedAffiliate.id)
      .then((res: any) => {
        setHasLicense(true);
        setCards(res.cards);
        setUsers(res.user);
      })
      .catch((err: any) => {
        if (err.response?.data?.message !== 'PAYMENTPLAN_NOT_FOUND') {
          createErrorNotification(err);
        } else {
          setHasLicense(false);
          setUsers([
            {
              id: 0,
              name: 'Maxime Muster',
            },
          ]);
          setCards([
            {
              id: 0,
              user: 0,
              name: 'Maxime Muster',
              uuid: 'Testkarte 1',
            },
          ]);
        }
      })
      .finally(() => {
        setIsLoadingCards(false);
      });
  };

  useEffect(() => {
    loadCards();
  }, []);

  return (
    <div className={'h-full'}>
      <SlotListModal
        isModalOpen={isShiftListModalOpen}
        setIsModalOpen={setIsShiftListModalOpen}
        start={null}
        end={null}
        cardId={selectedCardId}
      />
      <div className="relative h-full">
        {!hasLicense && <UpgradeSubscriptionBanner addon={'card'} />}

        <div className={'overflow-x-auto ' + (!hasLicense ? 'blur-sm' : '')}>
          {isLoadingCards ? (
            <LoadingComponent paddingTop={true} />
          ) : (
            <>
              <table className="table table-zebra lg:table-fixed">
                <thead>
                  <tr>
                    <th>{t('general.name')}</th>
                    <th>{t('cards.card_nr')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cards.map((card: any, cardKey: number) => {
                    return (
                      <tr key={cardKey}>
                        <td>
                          <UserSelect
                            id={cardKey}
                            onChange={assignCard}
                            selectedUser={card.user}
                            users={users}
                          />
                        </td>
                        <td>{card.id}</td>
                        <td>
                          <DropdownActions
                            actions={[
                              {
                                title: t('time_tracking.working_time'),
                                icon: <BsClock className={'hover:cursor-pointer'} size={17} />,
                                action: () => openShiftModal(card),
                              },
                              {
                                title: t('general.save'),
                                icon:
                                  isSaving === card.uuid ? (
                                    <span className={'loading loading-spinner'}></span>
                                  ) : (
                                    <BsCheckCircle
                                      className={'text-success hover:cursor-pointer'}
                                      size={17}
                                    />
                                  ),
                                action: () => saveCard(card.uuid),
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {cards.length === 0 && (
                <div className={'flex justify-center items-center mt-10'}>
                  <div
                    className={
                      'bg-base-200 bg-opacity-50 flex justify-center items-center absolute top-0 left-0 bottom-0 right-0 z-10'
                    }
                  >
                    <div className={'bg-white p-8 rounded mb-40 max-w-4xl'}>
                      <h1 className={'text-xl font-bold'}>Noch keine Karten gekauft</h1>
                      <p className={'mt-1'}>
                        Sie haben noch keine Karten erworben. Nach deinem Kauf werden die Karten
                        innerhalb von 2-3 Werktagen freigeschaltet und an dich verschickt. Sollte
                        das länger dauern als erwartet, kontaktiere uns gerne unter{' '}
                        <a href={'mailto:support@workplan.digital'} className={'link link-primary'}>
                          support@workplan.digital
                        </a>
                        .
                      </p>

                      <div className={'flex justify-center mt-4'}>
                        <div
                          className={'mx-auto btn btn-primary text-white'}
                          onClick={() => navigate('/settings/license')}
                        >
                          <BsShop size={17} /> Zum Shop
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
