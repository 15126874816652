import React, { useState } from 'react';
import { createErrorNotification, createNotification } from '@/helper/ApiHelper';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { AffiliateService } from '@/services/AffiliateService';
import Modal from '../General/Modal';
import { useTranslation } from 'react-i18next';

export const InviteEmployeeModal = ({ isModalOpen, setIsModalOpen, reloadInvites }) => {
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const initialUser = {
    firstname: '',
    lastname: '',
    email: '',
    start_date: DateTime.now().plus({ month: 1 }).startOf('month').toFormat('y-LL-dd'),
    end_date: '',
  };
  const [user, setUser] = useState(initialUser);

  const sendEmployeeInvite = () => {
    setIsLoading(true);
    AffiliateService.createInvite(
      user.email,
      user.firstname + ' ' + user.lastname,
      selectedAffiliate.id,
    )
      .then(async () => {
        createNotification(
          t('employee_page.invite_sent'),
          t('employee_page.invite_was_sent_to', { email: user.email }),
          'success',
        );
        await reloadInvites();
        setUser(initialUser);
        setIsModalOpen(false);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setUserData = (evt) => {
    setUser({ ...user, [evt.target.name]: evt.target.value });
  };

  return (
    <Modal
      isLoading={isLoading}
      modalName="add_employee"
      isOpen={isModalOpen}
      onSafe={sendEmployeeInvite}
      headline={t('employee_page.invite_employee')}
      onClose={() => {
        setUser(initialUser);
        setIsModalOpen(false);
      }}
    >
      <InviteEmployeeContent user={user} setUserData={setUserData} />
    </Modal>
  );
};

const InviteEmployeeContent = ({ user, setUserData }) => (
  <div className="space-y-1">
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text">{t('general.firstname')}</span>
      </label>
      <input
        type="text"
        name={'firstname'}
        value={user.firstname}
        onChange={setUserData}
        placeholder={t('general.firstname')}
        className="input input-bordered w-full"
      />
    </div>
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text">{t('general.lastname')}</span>
      </label>
      <input
        type="text"
        name={'lastname'}
        value={user.lastname}
        onChange={setUserData}
        placeholder={t('general.lastname')}
        className="input input-bordered w-full"
      />
    </div>
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text">{t('general.email')}</span>
      </label>
      <input
        type="text"
        name={'email'}
        value={user.email}
        onChange={setUserData}
        placeholder={t('general.email')}
        className="input input-bordered w-full"
      />
    </div>
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text">{t('general.start_of_employment')}</span>
      </label>
      <input
        type="date"
        name={'start_date'}
        value={user.start_date}
        onChange={setUserData}
        placeholder={t('general.start_of_employment')}
        className="input input-bordered w-full"
      />
    </div>
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text">{t('general.end_of_employment')}</span>
      </label>
      <input
        type="date"
        name={'end_date'}
        value={user.end_date}
        onChange={setUserData}
        placeholder={t('general.end_of_employment')}
        className="input input-bordered w-full"
      />
    </div>
  </div>
);
