import React, { Fragment, useEffect, useState } from 'react';
import { createErrorNotification } from '@/helper/ApiHelper';
import { useSelector } from 'react-redux';
import { BsPen, BsSave, BsTrash } from 'react-icons/bs';
import { LoadingComponent } from '@/components/General/LoadingComponent';
import { DateTime } from 'luxon';
import { DropdownActions } from '@/components/General/DropdownActions';
import { useLocation, useNavigate } from 'react-router-dom';
import EditShiftModal from '@/components/Modal/EditShiftModal';
import { DeleteShiftModal } from '@/components/Modal/DeleteShiftModal';
import FileSaver from 'file-saver';
import { SlotService } from '@/services/SlotService';
import Navbar from '@/components/Menu/Navbar';
import { Menu, Transition } from '@headlessui/react';
import { AffiliateService } from '@/services/AffiliateService';
import { ShiftService } from '@/services/ShiftService';
import { useTranslation } from 'react-i18next';

export default function ExportPage() {
  const { t } = useTranslation();
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);
  const { i18n } = useTranslation();
  const [allUsers, setAllUsers] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [showExtraFilter, setShowExtraFilter] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 1,
    size: '25',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [slots, setSlots] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const { state } = useLocation();
  const { user, start, end, projectId, cardId, from } = state ?? {
    user: { id: null, name: '' },
    start: DateTime.now().startOf('month').toISO(),
    end: DateTime.now().endOf('month').set({ millisecond: 0 }).toISO(),
    projectId: '',
    cardId: null,
  };
  const [timeRange, setTimeRange] = useState({
    userId: null,
    projectId: null,
    start: DateTime.fromISO(start),
    end: DateTime.fromISO(end),
    exportSingleShifts: false,
  } as {
    userId: number | null;
    projectId: number | null;
    start: DateTime | null;
    end: DateTime | null;
    exportSingleShifts: boolean;
  });
  const navigate = useNavigate();
  const [isExporting, setIsExporting] = useState(false as boolean);

  const openEditModal = (slot) => {
    setSelectedSlot(slot);
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (slot) => {
    setSelectedSlot(slot);
    setIsDeleteModalOpen(true);
  };

  const getSlots = (
    userId: string | null = null,
    projectId: string | null = null,
    pStart: string = '',
    pEnd: string = '',
    pageId = 1,
    pageSize = '25',
  ) => {
    if (pStart === '') {
      pStart = timeRange.start?.toISO({ suppressMilliseconds: true });
    }

    if (pEnd === '') {
      pEnd = timeRange.end?.toISO({ suppressMilliseconds: true });
    }

    setIsLoading(true);
    SlotService.getPeriod(
      pStart,
      pEnd,
      selectedAffiliate.id,
      userId,
      cardId,
      projectId,
      pageId,
      pageSize,
    )
      .then((res: any) => {
        setSlots(res.data);
        setPagination({ ...res.pagination, current: pageId });
      })
      .catch((err) => {
        setSlots(false);
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportSlots = (fileType) => {
    setIsExporting(true);
    let exportEndpoint = SlotService.export;
    if (timeRange.exportSingleShifts) {
      exportEndpoint = ShiftService.export;
    }

    exportEndpoint({
      file_type: fileType,
      affiliate_id: selectedAffiliate.id,
      user_id: timeRange.userId,
      start: timeRange.start?.toISO({ suppressMilliseconds: true }) ?? '',
      end: timeRange.end?.toISO({ suppressMilliseconds: true }) ?? '',
      card_id: cardId,
      project_id: timeRange.projectId,
      language: i18n.language.slice(0, 2),
    })
      .then((response: any) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        FileSaver.saveAs(response.data, filename);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const getUsers = () => {
    ShiftService.getModal(selectedAffiliate.id)
      .then((res: any) => {
        setAllUsers(res);
        let user: any =
          res.filter((user: any) => {
            return user.id === parseInt(user.id);
          })[0] ?? [];
        setAllProjects(user?.projects ? Object.values(user.projects) : []);
      })
      .catch((err) => {
        createErrorNotification(err);
      });
  };

  useEffect(() => {
    let dateStart = null;
    if (start !== null) {
      dateStart = DateTime.fromISO(start);
    }

    let dateEnd = null;
    if (end !== null) {
      dateEnd = DateTime.fromISO(end);
    }

    setTimeRange({
      userId: user.id,
      projectId: projectId,
      start: dateStart,
      end: dateEnd,
    });
    getSlots(user.id, projectId, '', '');
    getUsers();
  }, []);

  const updateTimeRange = (
    userId = null,
    projectId = null,
    pStart: string = '',
    pEnd: string = '',
    pageId = null,
    pageSize = null,
  ) => {
    let tmpTimeRange = {};

    let dtStart: DateTime | null = null;
    try {
      dtStart = DateTime.fromFormat(pStart, 'y-MM-d');
      tmpTimeRange = { ...timeRange, start: dtStart };
    } catch (e) {
      dtStart = null;
    }

    let dtEnd: DateTime | null = null;
    try {
      dtEnd = DateTime.fromFormat(pEnd, 'y-LL-d');
      tmpTimeRange = { ...timeRange, end: dtEnd };
    } catch (e) {
      dtEnd = null;
    }

    if (userId === '') {
      userId = null;
      tmpTimeRange = { ...timeRange, userId: null };
    }

    if (projectId === '') {
      projectId = null;
      tmpTimeRange = { ...timeRange, projectId: null };
    }

    if (userId !== null) {
      tmpTimeRange = { ...timeRange, userId };
    }

    if (projectId !== null) {
      tmpTimeRange = { ...timeRange, projectId };
    }

    let page = pageId;
    if (page === null) {
      page = pagination.current;
    }

    if (pageSize === null) {
      pageSize = pagination.size;
    } else {
      page = 1;
    }

    let formatStart: string = dtStart
      ? (dtStart.toISO({ suppressMilliseconds: true }) as string)
      : (timeRange.start?.toISO({ suppressMilliseconds: true }) as string);
    let formatEnd: string = dtEnd
      ? (dtEnd.toISO({ suppressMilliseconds: true }) as string)
      : (timeRange.end?.toISO({ suppressMilliseconds: true }) as string);

    setTimeRange(tmpTimeRange);
    getSlots(tmpTimeRange.userId, tmpTimeRange.projectId, formatStart, formatEnd, page, pageSize);
  };

  let header = user?.name;
  if (header === null) {
    header = selectedAffiliate.title;
  }

  return (
    <>
      <div className={''}>
        <div
          className={
            'sticky top-0 z-10 bg-base-100 w-full flex flex-col justify-start items-center lg:items-start xl:flex-row xl:items-center'
          }
        >
          <div className={'flex flex-col w-full'}>
            <div className="items-center flex lg:space-y-0 flex-col lg:flex-row justify-between w-full border-0 border-b">
              <div className="flex flex-row items-center pt-4 lg:p-4">
                <input
                  type={'date'}
                  className={'input input-bordered max-w-xs'}
                  value={timeRange.start?.toFormat('y-LL-dd') ?? ''}
                  onChange={(e) => updateTimeRange(null, null, e.target.value, null)}
                />
                <span className="px-2">-</span>
                <input
                  type={'date'}
                  className={'input input-bordered max-w-xs lg:mr-4'}
                  onChange={(e) => updateTimeRange(null, null, null, e.target.value)}
                  value={timeRange.end?.toFormat('y-LL-dd') ?? ''}
                />
                <span
                  className={
                    'btn btn-ghost hidden lg:inline-flex ' + (showExtraFilter ? 'btn-active' : '')
                  }
                  onClick={() => setShowExtraFilter(!showExtraFilter)}
                >
                  {t('general.table_actions.more_filter')}
                </span>
              </div>

              <div
                className={
                  'grid grid-cols-2 gap-3 lg:flex w-full lg:w-fit lg:bg-base-100 p-4 lg:flex-col-reverse justify-end items-end'
                }
              >
                <div
                  className={
                    'btn btn-ghost lg:hidden w-full ' + (showExtraFilter ? 'btn-active' : '')
                  }
                  onClick={() => setShowExtraFilter(!showExtraFilter)}
                >
                  {t('general.table_actions.more_filter')}
                </div>

                <Menu as="div" className="relative text-left w-full">
                  <div>
                    <Menu.Button className="btn btn-primary w-full">
                      {isExporting ? (
                        <span className={'loading loading-spinner'}></span>
                      ) : (
                        <BsSave size={17} />
                      )}{' '}
                      {t('general.export')}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          <button
                            className={
                              'text-gray-900 group flex w-full items-center rounded-md px-2 py-3 text-sm hover:bg-base-200'
                            }
                            onClick={() => exportSlots('csv')}
                          >
                            CSV
                          </button>
                        </Menu.Item>
                        <Menu.Item>
                          <button
                            className={
                              'text-gray-900 group flex w-full items-center rounded-md px-2 py-3 text-sm hover:bg-base-200'
                            }
                            onClick={() => exportSlots('excel')}
                          >
                            Excel
                          </button>
                        </Menu.Item>
                        <Menu.Item>
                          <button
                            className={
                              'text-gray-900 group flex w-full items-center rounded-md px-2 py-3 text-sm hover:bg-base-200'
                            }
                            onClick={() => exportSlots('pdf')}
                          >
                            PDF
                          </button>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div
              className={
                'p-4 py-2 space-y-3 border-0 border-b ' + (!showExtraFilter ? ' hidden' : '')
              }
            >
              <div className={'grid grid-cols-2 gap-2 mt-2'}>
                <div className={'w-full'}>
                  <div className="label">
                    <span className="label-text">{t('general.employee')}</span>
                  </div>
                  <select
                    className="select select-bordered w-full"
                    value={timeRange.userId ?? ''}
                    onChange={(e) => updateTimeRange(e.target.value, null, null, null)}
                  >
                    <option value={''}>{t('general.all')}</option>
                    {allUsers.map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={'w-full'}>
                  <div className="label">
                    <span className="label-text">{t('general.project')}</span>
                  </div>
                  <select
                    className="select select-bordered w-full"
                    value={timeRange.projectId ?? ''}
                    onChange={(e) => updateTimeRange(null, e.target.value, null, null)}
                  >
                    <option value={''}>{t('general.all')}</option>
                    {allProjects.map((project) => {
                      return (
                        <option key={project.id} value={project.id}>
                          {project.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-control max-w sm:max-w-[50%]">
                <label className="label cursor-pointer">
                  <span className="label-text">{t('time_tracking.export_with_comment')}</span>
                  <input
                    type="checkbox"
                    checked={timeRange.exportSingleShifts ?? false}
                    onChange={(e) =>
                      setTimeRange({
                        ...timeRange,
                        exportSingleShifts: !timeRange.exportSingleShifts,
                      })
                    }
                    className="checkbox"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>

        {isLoading && (
          <div>
            <LoadingComponent noText={false} size={'md'} paddingTop={true} />
          </div>
        )}

        {slots?.length > 0 && (
          <table className="table table-zebra lg:table-fixed">
            <thead>
              <tr>
                <th>{t('general.table_headers.day')}</th>
                <th>{t('general.table_headers.name')}</th>
                <th>{t('general.table_headers.start')}</th>
                <th>{t('general.table_headers.end')}</th>
                <th className={'hidden lg:block'}>{t('general.table_headers.duration')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {slots.map((slot: any, key: number) => {
                return (
                  <tr key={key}>
                    <td className={'overflow-scroll lg:overflow-hidden'}>
                      {DateTime.fromISO(slot.start).toFormat('dd.LL.yyyy')}
                    </td>
                    <td className={'overflow-scroll lg:overflow-hidden'}>{slot.name}</td>
                    <td className={'overflow-scroll lg:overflow-hidden'}>
                      {slot.is_absent_full_day
                        ? ''
                        : DateTime.fromISO(slot.start).toFormat('HH:mm')}
                    </td>
                    <td className={'overflow-scroll lg:overflow-hidden'}>
                      {slot.is_absent_full_day ? '' : DateTime.fromISO(slot.end).toFormat('HH:mm')}
                    </td>
                    <td className={'overflow-scroll lg:overflow-hidden hidden lg:table-cell'}>
                      {Math.round(parseFloat(slot.duration) * 100) / 100} h
                    </td>
                    <td className={'h-full'}>
                      <DropdownActions
                        actions={[
                          {
                            title: t('general.table_actions.edit'),
                            icon: <BsPen className={'hover:cursor-pointer'} size={17} />,
                            action: () => openEditModal(slot),
                          },
                          {
                            title: t('general.table_actions.delete'),
                            icon: (
                              <BsTrash className={'text-error hover:cursor-pointer'} size={17} />
                            ),
                            action: () => openDeleteModal(slot),
                          },
                        ]}
                      />
                    </td>
                    <td className={'hidden'}></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {!isLoading && slots?.length === 0 && (
          <div className={'text-center my-4 flex flex-col space-y-4'}>
            <span>{t('time_tracking.no_shifts_found')}</span>
          </div>
        )}

        <div className={'flex flex-col justify-center items-center gap-5'}>
          <div className={'flex justify-center mt-10'}>
            <div className="join">
              {[...Array(pagination.total).keys()].map((pId) => {
                let page = pId + 1;
                return (
                  <input
                    key={pId}
                    className="join-item btn btn-square"
                    type="radio"
                    name="options"
                    aria-label={page}
                    checked={page == pagination.current}
                    onChange={() => updateTimeRange(null, null, null, null, page)}
                  />
                );
              })}
            </div>
          </div>
          <select
            value={pagination.size}
            onChange={(e) => updateTimeRange(null, null, null, null, null, e.target.value)}
            className={'select select-sm select-bordered mb-10'}
          >
            <option value={'25'}>25</option>
            <option value={'50'}>50</option>
            <option value={'100'}>100</option>
          </select>
        </div>
      </div>
      <EditShiftModal
        reload={() =>
          getSlots(
            timeRange?.userId,
            timeRange?.projectId,
            timeRange?.start?.toISO({ suppressMilliseconds: true }),
            timeRange?.end?.toISO({ suppressMilliseconds: true }),
            pagination?.current,
            pagination?.size,
          )
        }
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        shiftId={selectedSlot?.id}
      />
      <DeleteShiftModal
        reload={() =>
          getSlots(
            timeRange?.userId,
            timeRange?.projectId,
            timeRange?.start?.toISO({ suppressMilliseconds: true }),
            timeRange?.end?.toISO({ suppressMilliseconds: true }),
            pagination?.current,
            pagination?.size,
          )
        }
        isAbsence={selectedSlot?.is_absence}
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        shiftId={selectedSlot?.id}
        date={DateTime.fromISO(selectedSlot?.start).toFormat('dd.LL.')}
        userName={selectedSlot?.name}
      />
    </>
  );
}
