import React from 'react';
import { Outlet } from 'react-router-dom';

const OnboardingLayout = () => {
  return (
    <div className={'flex flex-col flex-1 h-full'}>
      <div className={'m-auto w-full'}>
        <Outlet />
      </div>
    </div>
  );
};

export default OnboardingLayout;
