import { apiRequest } from '@/helper/ApiHelper';

export const CardService = {
  getCards: async (affiliate_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/cards/' + affiliate_id,
        method: 'GET',
      })
        .then(resolve)
        .catch(reject);
    });
  },
  assignCard: async (card_id: string, user_id: string) => {
    return new Promise((resolve, reject) => {
      return apiRequest({
        url: '/api/cards/assign',
        method: 'POST',
        data: {
          card_id: card_id,
          user_id: user_id,
        },
      })
        .then(resolve)
        .catch(reject);
    });
  },
};
