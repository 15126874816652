import React, { useEffect, useState } from 'react';
import Modal from '@/components/General/Modal';
import { DateTime } from 'luxon';
import { createErrorNotification } from '@/helper/ApiHelper';
import { BsCheckSquare, BsPlusLg, BsXSquare } from 'react-icons/bs';
import { t } from 'i18next';
import { AbsenceService } from '@/services/AbsenceService';
import { useSelector } from 'react-redux';
import { Button } from '../ui/button';

export const AbsenceRequestDetailsModal = ({
  isModalOpen,
  setIsModalOpen,
  reload = () => {},
  absenceId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [selectedAbsence, setSelectedAbsence] = useState({}) as any;
  const [newComment, setNewComment] = useState('');
  const selectedAffiliate = useSelector((state: any) => state.affiliate.selectedAffiliate);

  useEffect(() => {
    if (isModalOpen === true) {
      getAbsence(absenceId);
    }
  }, [isModalOpen]);

  const getAbsence = (absenceId, skipLoader = false) => {
    setIsLoading(!skipLoader);
    AbsenceService.getAbsence(absenceId)
      .then((res: any) => {
        setSelectedAbsence(res);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateAbsence = (absenceId, status) => {
    setIsSaving(true);
    AbsenceService.validateAbsence(absenceId, status)
      .then(() => {
        setSelectedAbsence({ ...selectedAbsence, status: status });
        reload();
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const deleteAbsence = (absenceId) => {
    setIsSaving(true);
    AbsenceService.deleteAbsence(absenceId)
      .then(() => {
        reload();
        setIsModalOpen(false);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const updateAbsence = () => {
    setIsAddingComment(true);
    AbsenceService.updateAbsence(absenceId, newComment)
      .then(() => {
        setNewComment('');
        getAbsence(absenceId, true);
      })
      .catch((err) => {
        createErrorNotification(err);
      })
      .finally(() => {
        setIsAddingComment(false);
      });
  };

  return (
    <>
      <Modal
        modalName="show_absence_details"
        headline={t('time_tracking.absence_request')}
        isOpen={isModalOpen}
        disableSafe={false}
        onSafe={() => {}}
        primaryButtonClass={'hidden'}
        showDeleteButton={selectedAffiliate.isAdmin}
        onDelete={() => deleteAbsence(selectedAbsence.id)}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="space-y-1">
          <div className="join join-vertical w-full">
            {isLoading && (
              <div className={'flex justify-center py-6'}>
                <span className={'loading loading-lg loading-spinner'}></span>
              </div>
            )}

            {!isLoading && (
              <div>
                <div className={'grid grid-cols-3'}>
                  <div>
                    <span className="text-xs">{t('general.name')}</span>
                    <br />
                    <span className="font-bold">{selectedAbsence.user?.name}</span>
                  </div>
                  <div className="text-center self-end"></div>
                  <div>
                    <span className="text-xs">{t('time_tracking.type')}</span>
                    <br />
                    <span className="font-bold">
                      {t('time_tracking.absence.' + selectedAbsence.type)}
                    </span>
                  </div>
                </div>
                <div className={'grid grid-cols-3'}>
                  <div>
                    <span className="text-xs">{t('time_tracking.start')}</span>
                    <br />
                    <span className="font-bold">
                      {DateTime.fromISO(selectedAbsence.start).toFormat('dd.MM.')}
                    </span>
                  </div>
                  <div className="text-center self-end">bis</div>
                  <div>
                    <span className="text-xs">{t('time_tracking.end')}</span>
                    <br />
                    <span className="font-bold">
                      {DateTime.fromISO(
                        selectedAbsence.end ? selectedAbsence.end : selectedAbsence.start,
                      ).toFormat('dd.MM.')}
                    </span>
                  </div>
                </div>
                <div className={'grid grid-cols-3'}>
                  <div>
                    <span className="text-xs">{t('time_tracking.status')}</span>
                    <br />
                    <span className="font-bold">
                      {t('time_tracking.absence.' + selectedAbsence.status)}
                    </span>
                  </div>
                  <div className="text-center self-end"></div>
                  <div>
                    <span className="text-xs"></span>
                    <br />
                    <span className="font-bold"></span>
                  </div>
                </div>

                <hr className={'my-5'} />

                {selectedAbsence.comments?.map((comment, key) => {
                  return (
                    <div key={key} className="w-full p-2 rounded border border-base-200 mb-2">
                      <p>{comment.text}</p>
                      <div className={'flex flex-row justify-end text-sm text-gray-400'}>
                        {selectedAbsence.user?.name} -{' '}
                        {DateTime.fromISO(comment.posted).toFormat('dd.MM.')}
                      </div>
                    </div>
                  );
                })}

                <div className="form-control">
                  <input
                    type="text"
                    name={'comment'}
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder={t('general.comment')}
                    className="input input-bordered w-full"
                  />
                </div>
                <div className={'flex flex-row justify-end'}>
                  <Button
                    className={'mt-3'}
                    variant={'outline'}
                    disabled={newComment === ''}
                    onClick={updateAbsence}
                  >
                    {isAddingComment ? (
                      <span className={'loading-md loading-spinner'}></span>
                    ) : (
                      <BsPlusLg size={17} className={'rounded-none mr-2 h-4 w-4'} />
                    )}
                    {t('general.add_comment')}
                  </Button>
                </div>
              </div>
            )}

            {selectedAffiliate.isAdmin ? (
              <div className={'flex justify-center space-x-3 pt-5'}>
                <div
                  className={'btn btn-success text-white ' + (isSaving ? 'btn-ghost' : '')}
                  onClick={() => validateAbsence(selectedAbsence.id, 'accepted')}
                >
                  {isSaving ? (
                    <span className={'loading-md loading-spinner'}></span>
                  ) : (
                    <BsCheckSquare size={17} className={'rounded-none'} />
                  )}{' '}
                  {t('general.accept')}
                </div>
                <div
                  className={'btn btn-error ' + (isSaving ? 'btn-ghost' : '')}
                  onClick={() => validateAbsence(selectedAbsence.id, 'declined')}
                >
                  {isSaving ? (
                    <span className={'loading-md loading-spinner'}></span>
                  ) : (
                    <BsXSquare size={17} className={'rounded-none'} />
                  )}{' '}
                  {t('general.decline')}
                </div>
              </div>
            ) : (
              <div className={'flex justify-center space-x-3 pt-5'}>
                <div
                  className={'btn btn-error ' + (isSaving ? 'btn-ghost' : '')}
                  onClick={() => validateAbsence(selectedAbsence.id, 'declined')}
                >
                  {isSaving ? (
                    <span className={'loading-md loading-spinner'}></span>
                  ) : (
                    <BsXSquare size={17} className={'rounded-none'} />
                  )}{' '}
                  {t('general.withdraw')}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
