import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    firstname: null,
    lastname: null,
    email: null,
    needs_onboarding: false,
  },
  reducers: {
    setUserData(state, action) {
      state.id = action.payload.id;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.needs_onboarding = action.payload.needs_onboarding;
    },
    clearState(state) {
      state.id = null;
      state.firstname = null;
      state.lastname = null;
      state.email = null;
      state.needs_onboarding = false;
    },
  },
});

export const { setUserData, clearState } = userSlice.actions;

export default userSlice.reducer;
