export function getCookie(name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts !== undefined && parts.length === 2) {
    return parts?.pop().split(';').shift();
  }

  return null;
}

// store cookie 3 months
export function setCookie(name, value) {
  document.cookie = `${name}=${value}; path=/; max-age=${60 * 60 * 24 * 30 * 3};`;
}

export function deleteCookie(name) {
  document.cookie = `${name}=; path=/; max-age=-1;`;
}
